// This file contains re-used UI view related constants

/* The amount of time to wait before doing some work that is triggered by user typing */
export const USER_TYPING_DEBOUNCE_TIME = 500;
// Width of the selected section border
export const SELECTED_SECTION_BORDER_WIDTH = 1.5;

export const HTTP = {
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    OFFLINE: 0,
    SERVER: 500,
};

export const SPINNER_DELAY = 300;

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export const enum ScreenName {
    HELP_CENTER = 'portalHelpCenter',
    HELP_CENTER_CUSTOMIZE_SIDEBAR = 'portalCustomizeHelpCenter',
    HELP_CENTER_CUSTOMIZE_ANNOUNCEMENT_SIDEBAR = 'portalCustomizeHelpCenterAnnouncement',
    HELP_CENTER_CUSTOMIZE_LOOK_AND_FEEL_SIDEBAR = 'portalCustomizeLookAndFeelSidebar',
    HELP_CENTER_CUSTOMIZE_LOGIN_ANNOUNCEMENT_SIDEBAR = 'portalCustomizeLoginAnnouncementSidebar',
    HELP_CENTERS_MANAGEMENT = 'helpCentersManagement',
    MANAGE_PROJECTS = 'helpCenterManageProjects',
    FEEDBACK = 'portalRequestFeedback',
    FEEDBACK_SUBMITTED = 'portalRequestFeedbackCommentSubmitted',
    LOGIN = 'portalLogin',
    LOGIN_PASSWORD = 'portalLoginPassword',
    LOGIN_FORGOT_PASSWORD_CONFIRMATION = 'portalLoginForgotPasswordConfirmation',
    LOGIN_SINGLE_SIGN_ON = 'portalLoginSingleSignOn',
    LOGIN_SINGLE_SIGN_ON_CUSTOMER = 'portalLoginSingleSignOnCustomer',
    LOGIN_ACTION = 'portalLoginAction',
    LOGIN_ANNOUNCEMENT_CARD = 'portalLoginAnnouncementCard',
    LOGIN_SSO_TEST_MODE_ERROR = 'portalLoginSsoTestModeError',
    RESET_PASSWORD = 'portalResetPassword',
    UNSUBSCRIBE = 'portalUnsubscribeConfirmation',
    REQUEST_CREATE = 'portalRequestCreate',
    REQUEST_DETAILS = 'portalRequestDetails',
    REQUEST_LIST = 'portalRequests',
    APPROVAL_LIST = 'portalApprovals',
    KNOWLEDGE_CATEGORY = 'categoryPage',
    REQUEST_CREATE_ANONYMOUS_SUCCESS = 'requestCreateAnonymousSuccess',
    PORTAL_HOME = 'singlePortal',
    REQUEST_GROUP_LIST = 'requestGroupList',
    REQUEST_GROUP = 'requestGroup',
    SIGNUP = 'portalSignup',
    INVITED_SIGNUP = 'portalAcceptInvite',
    COMPLETE_SIGNUP = 'portalCompleteSignup',
    VERIFY_EMAIL = 'portalVerifyEmail',
    COMPLETE_SIGNUP_TOKEN_EXPIRED = 'portalCompleteSignupTokenExpired',
    COMPLETE_SIGNUP_TOKEN_INVALID = 'portalCompleteSignupTokenInvalid',
    CHECK_EMAIL = 'portalValidateEmail',
    KNOWLEDGE_ARTICLE = 'knowledgeArticle',
    KNOWLEDGE_ALL_SEARCH_RESULTS = 'knowledgeAllSearchResults',
    USER_PROFILE = 'portalProfile',
    ADD_ANNOUNCEMENT_SIDEBAR = 'addAnnouncementSidebar',
    REQUEST_LIST_CUSTOMIZE_SIDEBAR = 'requestListCustomizeSidebar',
    REQUEST_APPROVAL = 'requestApproval',
    PORTAL_REORDER_SETTINGS = 'portalReorderSettings',
    ERROR_NOT_FOUND = 'errorNotFound',
    PORTAL_CUSTOMIZE_ANNOUNCEMENT_SIDEBAR = 'portalCustomizeAnnouncementSidebar',
    VIEW_TOPIC = 'viewTopic',
    EDIT_TOPICS_LAYOUT = 'editTopicsLayout',
    EDIT_TOPIC = 'editTopic',
    PORTAL_ACCESS_FORBIDDEN = 'PortalAccessForbidden',
    EMBED_REQUEST_CREATE = 'embedRequestCreate',
    EMBED_SINGLE_PORTAL = 'embedSinglePortal',
    EMBED_KB_ARTICLE = 'embedKBArticle',
    VIEW_HOMEPAGE = 'portalHelpCenter',
    EDIT_HOMEPAGE = 'editHomepage',
    HELP_DESK_REPORTING = 'helpDeskReporting',
    UNIFIED_HELP_HOME_PAGE = 'unifiedHelpHomePage',
    UNIFIED_HELP_TEST_HARNESS = 'unifiedHelpTestHarness',
    ADD_HELP_CONTENT_KNOWLEDGE_RESOURCES = 'addHelpContentKnowledgeResources',
    ADD_HELP_CONTENT_EXTERNAL_LINKS = 'addHelpContentExternalLinks',
    HELP_CENTER_SEARCH = 'PortalSearch',
    CONVERSATION_PAGE = 'conversationPage',
    CONSENT_FORM_PAGE = 'consentFormPage',
    AI_ANSWER = 'aiAnswer',
}

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export const enum ExperienceName {
    REQUEST_CREATE = 'request-create',
    HELP_CENTER = 'view-help-center',
    FEATURED_AND_SORTED_PORTALS = 'featured-and-sorted-portals',
}

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export const enum BrowserMetricViewName {
    REQUEST_DETAIL_VIEW = 'request-detail',
    COMMENT_EDITOR_VIEW = 'comment-editor',
}

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export const enum EngagementMessages {
    SIDEBAR_REFACTOR = 'jsm-portal-sidebar-refactor',
    PORTAL_REORDER_EXPERIENCE = 'jsm-portal-reordering-change-boarding',
    TOPICS = 'jsm-customer-portal-topics',
    EXTERNAL_RESOURCES_IN_TOPICS = 'external-resources-in-topics-pill',
    TOPICS_FOR_ALL = 'topics-for-all-customisation',
    CHANGE_BOARD_CUSTOMISE_BUTTON = 'jsm_admins_customization_with_button',
    CHANGE_BOARD_HELP_CENTER_SETTINGS_BUTTON = 'jsm_admins_help_center_settings_button',
    CHANGE_BOARD_ADMINS_FREE_AND_STANDARD_EDITION = 'changeboarding_jsm_admins_home_free_std',
    CHANGE_BOARD_ADMINS_SINGLE_PORTAL_SCREEN = 'changeboarding_jsm_admins_singleportal',
    CHANGE_BOARD_ADMINS_PREMIUM_ENTERPRISE = 'changeboarding_jsm_admins_premium_entr',
    ONBOARD_VA_REPORTING = 'va-reporting-changeboarding-message',
    ONBOARD_VA_ON_HC = 'va-on-hc-onboarding-message',
}

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export const enum EngagementMessagesTarget {
    CHANGE_BOARDING_SPOTLIGHT = 'changeBoardingSpotLight',
    CHANGE_BOARDING_CUSTOMISE_BUTTON_SPOTLIGHT = 'changeBoardingCustomiseButtonSpotLight',
    CHANGE_BOARD_HELP_CENTER_SETTINGS_BUTTON = 'changeBoardHelpCenterSettingsButton',
    MUTLI_HC_CHANGE_BOARDING_SPOTLIGHT = 'multihcDropdownChangeBoardingSpotlight',
    REPORTING_ONBOARDING_SPOTLIGHT = 'reportingOnboardingSpotlight',
}

export const PORTAL_PARENT_PRODUCT = 'JSM-Portal';
export const EMBEDDED_CONFLUENCE_SHIM_URL = '/plugins/servlet/servicedesk/customer/confluence/shim/';

export const UNIFIED_HELP_CENTER_BASE_PATH = '/helpcenter/unified';
export const HELP_CENTERS_MANAGEMENT_BASE_PATH = '/helpcenter-management';
export const VA_ON_HC_CONVERSATION_PATH = '/conversation';
export const VA_ON_PORTAL_CONVERSATION_PATH = '/portal/:portalId/conversation';

// Help center defaults
export const DEFAULT_HELP_CENTER_TYPE = 'BASIC';
