/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<b2e025605075795c90b342ea584d4474>>
 * @relayHash ae1f359bc6015e8dcf152a2cd6fab362
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7740d0b901e891b67df083235bccf41f98e80acaf21ad2313957a37602688dcb

import type { ConcreteRequest, Query } from 'relay-runtime';
export type contentGapTableQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
};
export type contentGapTableQuery$data = {
  readonly helpCenter: {
    readonly helpCenterReportingById: {
      readonly __typename: "HelpCenterReporting";
      readonly contentGapIndicatorsWithMetaData: {
        readonly contentGapIndicators: ReadonlyArray<{
          readonly keywords: string;
          readonly questionsCount: number;
        }> | null | undefined;
      } | null | undefined;
    } | {
      readonly __typename: "QueryError";
      readonly message: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
};
export type contentGapTableQuery = {
  response: contentGapTableQuery$data;
  variables: contentGapTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "cloudId",
        "variableName": "cloudId"
      }
    ],
    "concreteType": "HelpCenterQueryApi",
    "kind": "LinkedField",
    "name": "helpCenter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "helpCenterAri",
            "variableName": "helpCenterAri"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "helpCenterReportingById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HelpCenterContentGapIndicatorsWithMetaData",
                "kind": "LinkedField",
                "name": "contentGapIndicatorsWithMetaData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HelpCenterContentGapIndicator",
                    "kind": "LinkedField",
                    "name": "contentGapIndicators",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "keywords",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "questionsCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "HelpCenterReporting",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "QueryError",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "contentGapTableQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "contentGapTableQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7740d0b901e891b67df083235bccf41f98e80acaf21ad2313957a37602688dcb",
    "metadata": {},
    "name": "contentGapTableQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "70c83cc3bb69a172264b4f85b5056a52";

export default node;
