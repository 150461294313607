import { defineMessages } from 'react-intl-next';

export default defineMessages({
    headerText: {
        defaultMessage: 'Help centers',
        description: 'Help centers management screen header text',
        id: 'help.center.management.page.header.text',
    },
    headerDescription: {
        defaultMessage: 'Set up and manage your help centers here.',
        description: 'Help centers management screen header description',
        id: 'help.center.management.page.header.description',
    },
    headerDescriptionReadMoreLink: {
        defaultMessage: 'Read more about help centers',
        description: 'Help centers management screen header description read more link',
        id: 'help.center.management.page.header.description.read.more.link',
    },
    helpCenterAddButtonText: {
        defaultMessage: 'Create help center',
        description: 'Help centers management screen add help center button text',
        id: 'help.center.management.add.help.center.button.text',
    },
    genericErrorHeading: {
        defaultMessage: 'Something bad happened',
        description: 'Generic error when something could not be loaded',
        id: 'help.center.management.page.generic.error.heading',
    },
    genericErrorMessage: {
        defaultMessage: 'Looks like we’re having trouble retrieving some information, please refresh the page.',
        description: 'Generic error when something could not be loaded',
        id: 'help.center.management.page.generic.error.message',
    },
    authPageMessage: {
        defaultMessage: `You don't have permission to view this page.`,
        description: 'Message text for unauthorized page of portal reordering',
        id: 'help.center.management.page.un.auth.action.message',
    },
    callToActionUrlText: {
        defaultMessage: 'Go to help center',
        description: 'Call to action text for unauthorized page of portal reordering',
        id: 'help.center.management.page.un.auth.action.text',
    },
    upgradeTitleCreateClick: {
        defaultMessage: 'Upgrade to create new help centers',
        description: 'Popup title to upgrade to create help center',
        id: 'help.center.management.upgrade.title.create.click',
    },
    upgradeDescriptionCreateClick: {
        defaultMessage: 'You need to be on a Premium or Enterprise plan to create multiple help centers on your site.',
        description: 'Popup description to upgrade to create help center',
        id: 'help.center.management.upgrade.description.create.click',
    },
    internalSupportHelpCentersHeaderText: {
        defaultMessage: 'Internal support help centers',
        description: 'Header for internal support help centers section',
        id: 'help.center.internal.section.header.text.non-final',
    },
    externalSupportHelpCentersHeaderText: {
        defaultMessage: 'External support help centers',
        description: 'Header for external support help centers section',
        id: 'help.center.external.section.header.text.non-final',
    },
});
