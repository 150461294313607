import React from 'react';
import { useRouter } from 'react-resource-router';
import invariant from 'tiny-invariant';
import type { PaperAppearance } from 'view/layout/paper';
import { ViewTopic } from 'view/topic';
import type { topicQuery } from 'view/topic/__generated__/topicQuery.graphql';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { createRouteWithLayout, useRelayResource } from '@atlassian/help-center-common-util/resource-router';
import ViewTopicResource from './resources/view-topic';

const paperAppearance: PaperAppearance = 'normal';

const PageLoader = () => {
    const [
        {
            match: {
                params: { id },
            },
        },
    ] = useRouter();
    invariant(id);
    const { queryReference } = useRelayResource<topicQuery>(ViewTopicResource);
    if (!queryReference) return null;
    return <ViewTopic topicId={id} queryReference={queryReference} />;
};

const layoutProps = {
    paperAppearance,
    fadeInSearchModal: true,
};

export const viewTopicRoute = createRouteWithLayout(
    {
        name: 'view-topic',
        path: '/topic/:id',
        exact: true,
        component: PageLoader,
        resources: [ViewTopicResource],
    },
    ScreenName.VIEW_TOPIC,
    layoutProps
);
