import { getMeta } from '@helpCenter/util/meta';
import { isMoveToManagementRouterEnabled } from 'feature-flags';
import type { RouterContext } from 'react-resource-router';
import { HelpHelpCenterAri } from '@atlassian/ari';
import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';
import type { metricGridReportingQuery } from './__generated__/metricGridReportingQuery.graphql';
import MetricGridReportingQuery from './__generated__/metricGridReportingQuery.graphql';

export const HelpReportingMetricTileResource = createRelayResource<metricGridReportingQuery>({
    type: 'HELP_REPORTING_PAGE',
    getQuery: ({ match }: RouterContext, { cloudId, helpCenterAri }: { cloudId: string; helpCenterAri: string }) => {
        let computedHelpCenterAri = helpCenterAri;
        if (isMoveToManagementRouterEnabled()) {
            const activationId = getMeta('activation-id') || '';
            const helpCenterId = match.params.helpcenterId || '';
            computedHelpCenterAri = HelpHelpCenterAri.create({ activationId, helpCenterId }).toString();
        }

        return {
            parameters: MetricGridReportingQuery,
            variables: {
                cloudId,
                helpCenterAri: computedHelpCenterAri,
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
