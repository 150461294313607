import { isVAOnHelpCenterGAEnabled } from 'feature-flags';
import ViewHomepageQuery from 'view/advanced-help-center/view-homepage/__generated__/viewHomepageQuery.graphql';
import type { viewHomepageQuery } from 'view/advanced-help-center/view-homepage/__generated__/viewHomepageQuery.graphql';
import type { searchLoaderVirtualAgentEnabledQuery } from 'view/search/__generated__/searchLoaderVirtualAgentEnabledQuery.graphql';
import SearchLoaderVirtualAgentEnabledQuery from 'view/search/__generated__/searchLoaderVirtualAgentEnabledQuery.graphql';
import { createRelayResource } from '@atlassian/help-center-common-util/resource-router/resource-router';

export const ViewHomepageResource = createRelayResource<viewHomepageQuery>({
    type: 'VIEW_HOMEPAGE',
    getQuery: (_, { cloudId, helpCenterAri, layoutAri }) => {
        return {
            parameters: ViewHomepageQuery,
            variables: {
                cloudId,
                helpCenterAri,
                layoutAri,
                filter: { isEditMode: false },
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});

export const searchComponentVirtualAgentResource = createRelayResource<searchLoaderVirtualAgentEnabledQuery>({
    type: 'HELP_CENTER_SEARCH_VIRTUAL_AGENT',
    getQuery: (_, { cloudId, helpCenterAri }: { cloudId: string; helpCenterAri: string }) => {
        return {
            parameters: SearchLoaderVirtualAgentEnabledQuery,
            variables: {
                cloudId,
                helpCenterAri,
                isVaOnHcGAEnabled: isVAOnHelpCenterGAEnabled(),
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
