/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<d15af1bce471efe12a48b07fa17988f7>>
 * @relayHash 991b98f5736318a4e88c74465c6500ab
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8feeb79eb0bda5aa477cfda2154b45576f9cf9ccf35200dcfedbbd677743d229

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type searchResultRequestFormQuery$variables = {
  cloudId: string;
  helpCenterAri?: string | null | undefined;
  portalIds?: ReadonlyArray<string> | null | undefined;
  queryTerm: string;
  resultLimit?: number | null | undefined;
};
export type searchResultRequestFormQuery$data = {
  readonly helpObjectStore: {
    readonly __typename: "HelpObjectStoreQueryApi";
    readonly " $fragmentSpreads": FragmentRefs<"requestFormsFragment">;
  };
};
export type searchResultRequestFormQuery = {
  response: searchResultRequestFormQuery$data;
  variables: searchResultRequestFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "portalIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryTerm"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resultLimit"
},
v5 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "searchResultRequestFormQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v6/*: any*/),
          "concreteType": "HelpObjectStoreQueryApi",
          "kind": "LinkedField",
          "name": "helpObjectStore",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "requestFormsFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "helpObjectStore"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "searchResultRequestFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "HelpObjectStoreQueryApi",
        "kind": "LinkedField",
        "name": "helpObjectStore",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": "requestForms",
            "args": [
              {
                "fields": [
                  (v5/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "entityType",
                    "value": "REQUEST_FORM"
                  },
                  {
                    "kind": "Variable",
                    "name": "helpCenterAri",
                    "variableName": "helpCenterAri"
                  },
                  {
                    "kind": "Variable",
                    "name": "portalIds",
                    "variableName": "portalIds"
                  },
                  {
                    "kind": "Variable",
                    "name": "queryTerm",
                    "variableName": "queryTerm"
                  },
                  {
                    "kind": "Variable",
                    "name": "resultLimit",
                    "variableName": "resultLimit"
                  }
                ],
                "kind": "ObjectValue",
                "name": "searchInput"
              }
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "searchHelpObjects",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isExternal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "searchBackend",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayLink",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "containerDisplayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "searchAlgorithm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "entityType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iconUrl",
                    "storageKey": null
                  }
                ],
                "type": "HelpObjectStoreSearchResult",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                  }
                ],
                "type": "HelpObjectStoreQueryError",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "8feeb79eb0bda5aa477cfda2154b45576f9cf9ccf35200dcfedbbd677743d229",
    "metadata": {},
    "name": "searchResultRequestFormQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "767d90ed3aed24eab0f0598281dd235e";

export default node;
