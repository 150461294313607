/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<0a8d18311badb6cfefdbb49489dd92bc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type projectsListFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly avatar: {
        readonly small: string | null | undefined;
      } | null | undefined;
      readonly lead: {
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined;
      readonly name: string;
      readonly projectId: string | null | undefined;
      readonly webUrl: AGG$URL | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "projectsListFragment";
};
export type projectsListFragment$key = {
  readonly " $data"?: projectsListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectsListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "projectsListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JiraProjectEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JiraProject",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "projectId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "JiraAvatar",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "small",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "lead",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "picture",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "webUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JiraProjectConnection",
  "abstractKey": null
};
})();

(node as any).hash = "3eeb7301918e0096af7f6ae5ad2df33f";

export default node;
