import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { isAdvancedCustomizationEnabled } from 'feature-flags';
import { di } from 'react-magnetic-di';
import { LoadingSkeleton } from 'view/common/loading';
import { HEADER_HEIGHT } from 'view/common/styled';
import { Header as DefaultHeader } from 'view/layout/header';
import type { PaperAppearance } from 'view/layout/paper';
import { Paper } from 'view/layout/paper';
import { PrefetchBrandingModel } from 'view/layout/prefetch-branding-model';
import { AutoHeightBody } from 'view/layout/styled';
import * as grid from 'view/styles/grid';
import { Content, PageLayout } from '@atlaskit/page-layout';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { BodyClassName } from '@atlassian/help-center-common-component/body-class-name';
import { Flipper } from '@atlassian/help-center-common-component/flip-toolkit';
import { SidebarPusher } from '@atlassian/help-center-common-component/pushing-customize-sidebar';
import { isKoshEnabledForDefaultHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';

export const BANNER_HEIGHT = grid.multiple(23);

interface PageLayoutContentWrapperProps {
    children: JSX.Element;
}

const PageLayoutContentWrapper: React.FC<PageLayoutContentWrapperProps> = ({ children }) => (
    <PageLayout testId="page-layout">
        <Content>{children}</Content>
    </PageLayout>
);

export interface Props {
    children: JSX.Element;
    isAnonymous?: boolean;
    removeHeader?: boolean;
    headerless?: boolean;
    hideSearchButton?: boolean;
    helpCenterBanner: string | undefined;
    isBrandingLoaded?: boolean;
    paperAppearance?: PaperAppearance;
    // Used as a key to determine whether or not we should add fade transitions between pages
    // we use the top level path from app.tsx to differentiate between different pages
    path: string | undefined;
    searchShown: boolean;
    fadeInSearchModal: boolean;
    hideBanner?: boolean;
    isCSM?: boolean;
    prefetchBrandingModel?: boolean;
}

/**
 * Paper in this context is the floating paper that is ontop of the banner.
 * Banner will have the background image chosen by the admin as well as the
 * theme color as background color.
 */
const BannerWithGridLayoutDumb = ({
    children,
    paperAppearance,
    isAnonymous,
    removeHeader,
    headerless,
    hideSearchButton,
    helpCenterBanner,
    path,
    searchShown,
    isBrandingLoaded,
    prefetchBrandingModel,
    hideBanner,
    isCSM,
}: Props) => {
    di(isKoshEnabledForDefaultHelpCenter, isAdvancedCustomizationEnabled);

    const showBannerWithLoader = () => {
        if (isBrandingLoaded) {
            return !hideBanner && <Banner imageSrc={helpCenterBanner} />;
        }
        return (
            !hideBanner && (
                <UFOLoadHold name="banner-loader">
                    <LoadingContainer>
                        <LoadingSkeleton
                            data-testid="banner-skeleton"
                            height={BANNER_HEIGHT.unitless}
                            backgroundColor={token('color.skeleton.subtle', colors.N20)}
                        />
                    </LoadingContainer>
                </UFOLoadHold>
            )
        );
    };
    return (
        <PageLayoutContentWrapper>
            <SidebarPusherRoot>
                <FlipperWithFlexbox flipKey={searchShown} portalKey="search">
                    <Container>
                        {prefetchBrandingModel ? <PrefetchBrandingModel isAnonymous={isAnonymous} /> : null}
                        {/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve */}
                        <BodyClassName className="px-paper-layout" />
                        {!removeHeader && !headerless && (
                            <DefaultHeader
                                isAnonymous={isAnonymous}
                                logoTextColor="banner"
                                hideSearchButton={hideSearchButton}
                                showThemedBackground={false}
                                isModelCallPrefetched={prefetchBrandingModel}
                            />
                        )}
                        {isKoshEnabledForDefaultHelpCenter()
                            ? showBannerWithLoader()
                            : !hideBanner && <Banner imageSrc={helpCenterBanner} />}

                        {!removeHeader && headerless && <EmptyHeader />}
                        <PaperContainer>
                            <Paper appearance={paperAppearance} pageKey={path} isCSM={isCSM}>
                                {children}
                            </Paper>
                        </PaperContainer>

                        <AutoHeightBody />
                    </Container>
                </FlipperWithFlexbox>
            </SidebarPusherRoot>
        </PageLayoutContentWrapper>
    );
};

export default BannerWithGridLayoutDumb;

const CONTAINER_MIN_HEIGHT = '100vh';

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const SidebarPusherRoot = styled(SidebarPusher)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: ${CONTAINER_MIN_HEIGHT};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const PaperContainer = styled.div`
    /* TODO: Position property will be removed once new grid container is integrated. Currently exist to support child paper content container which is absolute */
    position: relative;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
`;

interface BannerProps {
    imageSrc?: string;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Banner = styled.div`
    height: ${BANNER_HEIGHT.px};
    background-color: ${(props) => token('color.background.brand.bold', props.theme.color)};
    background-image: ${(props: BannerProps) => (!!props.imageSrc ? `url(${props.imageSrc})` : 'none')};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const LoadingContainer = styled.div`
    height: ${BANNER_HEIGHT.px};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const EmptyHeader = styled.div`
    height: ${HEADER_HEIGHT}px;
    width: 100%;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const FlipperWithFlexbox = styled(Flipper)`
    display: flex;
    flex-direction: column;
`;
