/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<703bbe46ac5f24b92b5aecae5414c411>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type resourceIconFragment$data = {
  readonly displayLink: string;
  readonly entityType: string;
  readonly iconUrl: string;
  readonly isExternal: boolean;
  readonly " $fragmentType": "resourceIconFragment";
};
export type resourceIconFragment$key = {
  readonly " $data"?: resourceIconFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"resourceIconFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "resourceIconFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityType",
        "storageKey": null
      },
      "action": "THROW",
      "path": "entityType"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayLink",
        "storageKey": null
      },
      "action": "THROW",
      "path": "displayLink"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "iconUrl",
        "storageKey": null
      },
      "action": "THROW",
      "path": "iconUrl"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isExternal",
        "storageKey": null
      },
      "action": "THROW",
      "path": "isExternal"
    }
  ],
  "type": "HelpObjectStoreSearchResult",
  "abstractKey": null
};

(node as any).hash = "61adf56879f78dbab16536f41b38d4cf";

export default node;
