/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<9fa661aaca3a06b907cef888e18ab364>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type HelpObjectStoreSearchAlgorithm = "KEYWORD_SEARCH_ON_ISSUES" | "KEYWORD_SEARCH_ON_PORTALS_BM25" | "KEYWORD_SEARCH_ON_PORTALS_EXACT_MATCH" | "KEYWORD_SEARCH_ON_REQUEST_TYPES_BM25" | "KEYWORD_SEARCH_ON_REQUEST_TYPES_EXACT_MATCH" | "%future added value";
export type HelpObjectStoreSearchBackend = "JIRA" | "SEARCH_PLATFORM" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type requestFormItemFragment$data = {
  readonly __typename: "HelpObjectStoreSearchResult";
  readonly containerDisplayName: string | null | undefined;
  readonly description: string;
  readonly displayLink: string;
  readonly id: string;
  readonly isExternal: boolean;
  readonly searchAlgorithm: HelpObjectStoreSearchAlgorithm | null | undefined;
  readonly searchBackend: HelpObjectStoreSearchBackend | null | undefined;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"resourceIconFragment">;
  readonly " $fragmentType": "requestFormItemFragment";
};
export type requestFormItemFragment$key = {
  readonly " $data"?: requestFormItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"requestFormItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "requestFormItemFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      "action": "THROW",
      "path": "__typename"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      "action": "THROW",
      "path": "title"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      "action": "THROW",
      "path": "description"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayLink",
        "storageKey": null
      },
      "action": "THROW",
      "path": "displayLink"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "containerDisplayName",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isExternal",
        "storageKey": null
      },
      "action": "THROW",
      "path": "isExternal"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchBackend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchAlgorithm",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "resourceIconFragment"
    }
  ],
  "type": "HelpObjectStoreSearchResult",
  "abstractKey": null
};

(node as any).hash = "727e95b7f392a8f5165d17aa78e23fb8";

export default node;
