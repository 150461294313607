import { defineMessages } from 'react-intl-next';

export default defineMessages({
    helpcenterDetailsItem: {
        defaultMessage: 'Details',
        description: 'Label for the help center details item in the help center management screen sidebar',
        id: 'admin.management.sidebar.edit.details',
    },
    helpcenterManageProjectsItem: {
        defaultMessage: 'Projects',
        description: 'Label for the help center projects item in the help center management screen sidebar',
        id: 'admin.management.sidebar.manage.projects',
    },
    helpcenterVsaReportingItem: {
        defaultMessage: 'Virtual service agent',
        description: 'Label for the help center reporting item in the help center management screen sidebar',
        id: 'admin.management.sidebar.reporting',
    },
    helpcenterVaReportingItem: {
        defaultMessage: 'Virtual agent',
        description: 'Label for the help center reporting item in the help center management screen sidebar',
        id: 'admin.management.sidebar.reporting-old',
    },
    errorTitle: {
        defaultMessage: 'Something went wrong',
        description: 'Error title',
        id: 'admin.management.sidebar.error.title',
    },
    errorDescription: {
        defaultMessage: 'Refresh the page and try again',
        description: 'Error description',
        id: 'admin.management.sidebar.error.description',
    },
});
