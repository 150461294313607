/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<6f0e4b1df19eae003bd964b9fa9613d8>>
 * @relayHash ea767233fdda9d8440cb3717d691e63f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 815830907fec491e53762c001f75e54a70892ad6d39a151ec8c59b6ff9f0a99c

import type { ConcreteRequest, Query } from 'relay-runtime';
export type helpCenterBrandingQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
};
export type helpCenterBrandingQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "HelpCenter";
      readonly announcements: {
        readonly canEditHomePageAnnouncement: boolean | null | undefined;
        readonly homePageAnnouncements: ReadonlyArray<{
          readonly description: string | null | undefined;
          readonly descriptionTranslationsRaw: ReadonlyArray<{
            readonly locale: string;
            readonly localeDisplayName: string;
            readonly value: string;
          }> | null | undefined;
          readonly name: string | null | undefined;
          readonly nameTranslationsRaw: ReadonlyArray<{
            readonly locale: string;
            readonly localeDisplayName: string;
            readonly value: string;
          }> | null | undefined;
        }> | null | undefined;
      } | null | undefined;
      readonly helpCenterBranding: {
        readonly banner: {
          readonly fileId: string | null | undefined;
          readonly url: string | null | undefined;
        } | null | undefined;
        readonly colors: {
          readonly bannerTextColor: string | null | undefined;
          readonly primary: string | null | undefined;
        } | null | undefined;
        readonly homePageTitle: {
          readonly default: string;
          readonly translations: ReadonlyArray<{
            readonly locale: string;
            readonly localeDisplayName: string;
            readonly value: string;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly isBannerAvailable: boolean | null | undefined;
        readonly isLogoAvailable: boolean | null | undefined;
        readonly logo: {
          readonly fileId: string | null | undefined;
          readonly url: string | null | undefined;
        } | null | undefined;
        readonly useDefaultBanner: boolean | null | undefined;
      };
      readonly name: {
        readonly default: string;
        readonly translations: ReadonlyArray<{
          readonly locale: string;
          readonly localeDisplayName: string;
          readonly value: string;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly siteDefaultLanguageTag: string | null | undefined;
      readonly userLanguageTag: string | null | undefined;
    } | {
      readonly __typename: "QueryError";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
};
export type helpCenterBrandingQuery = {
  response: helpCenterBrandingQuery$data;
  variables: helpCenterBrandingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locale",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "localeDisplayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "default",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "HelpCenterTranslation",
    "kind": "LinkedField",
    "name": "translations",
    "plural": true,
    "selections": (v4/*: any*/),
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpCenterName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fileId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpCenterBranding",
  "kind": "LinkedField",
  "name": "helpCenterBranding",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HelpCenterHomePageTitle",
      "kind": "LinkedField",
      "name": "homePageTitle",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HelpCenterLogo",
      "kind": "LinkedField",
      "name": "logo",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HelpCenterBanner",
      "kind": "LinkedField",
      "name": "banner",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HelpCenterBrandingColors",
      "kind": "LinkedField",
      "name": "colors",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bannerTextColor",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBannerAvailable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLogoAvailable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useDefaultBanner",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpCenterAnnouncements",
  "kind": "LinkedField",
  "name": "announcements",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEditHomePageAnnouncement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HelpCenterAnnouncement",
      "kind": "LinkedField",
      "name": "homePageAnnouncements",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpCenterTranslation",
          "kind": "LinkedField",
          "name": "nameTranslationsRaw",
          "plural": true,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpCenterTranslation",
          "kind": "LinkedField",
          "name": "descriptionTranslationsRaw",
          "plural": true,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "siteDefaultLanguageTag",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userLanguageTag",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "helpCenterBrandingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "RequiredField",
                    "field": (v8/*: any*/),
                    "action": "THROW",
                    "path": "helpCenter.helpCenterById.helpCenterBranding"
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "type": "HelpCenter",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "QueryError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "helpCenterBrandingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "type": "HelpCenter",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "815830907fec491e53762c001f75e54a70892ad6d39a151ec8c59b6ff9f0a99c",
    "metadata": {},
    "name": "helpCenterBrandingQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f8dbe661614c584ad1b6b9306194ee99";

export default node;
