import type { ComponentType, ReactNode } from 'react';
import React, { Suspense, useCallback, useState } from 'react';
import type { FetchError } from 'util/fetch';
import { isFetchNetworkOrClientError } from 'util/fetch';
import { getCloudId } from 'util/meta';
import { isDefaultHelpCenter } from '@helpCenter/util/advanced-help-center';
import { getIsMultiHCEnabled } from '@helpCenter/util/env';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import ErrorScreen from 'assets/images/project-mapping-error.svg';
import { isMoveToManagementRouterEnabled, isVaRebrandToVsaEnabled } from 'feature-flags';
import { debounce, noop } from 'lodash';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { AnalyticsContext, useAnalyticsEvents } from '@atlaskit/analytics-next';
import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import EmptyState from '@atlaskit/empty-state';
import Heading from '@atlaskit/heading';
import Search from '@atlaskit/icon/glyph/search';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/analytics-bridge';
import { TRACK_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { Link } from '@atlassian/help-center-common-component/analytics';
import { DropdownItem } from '@atlassian/help-center-common-component/analytics/dropdown-item';
import { ScreenName, USER_TYPING_DEBOUNCE_TIME } from '@atlassian/help-center-common-component/constants';
import { FireScreenEventOnMount } from '@atlassian/help-center-common-util/analytics/fire-screen-event';
import { ExperienceSuccess, useUFOComponentExperience } from '@atlassian/ufo';
import { useHelpCenterAri } from '../../hooks/use-help-center-ari';
import { AdminManagementLayout } from '../admin-management-layout';
import { SettingsBreadcrumbs } from '../settings-breadcrumbs';
import type { manageProjects_GetProjectsQuery } from './__generated__/manageProjects_GetProjectsQuery.graphql';
import type { manageProjectsQuery } from './__generated__/manageProjectsQuery.graphql';
import { manageProjectsExperience } from './experiences';
import { ManageProjectsLayoutSkeleton } from './manage-projects-layout-skeleton';
import messages from './messages';
import { ProjectsList } from './projects-list';
import { SyncProjectsToggle } from './sync-projects-toggle';
import type { StatusTextMap } from './types';
import { ProjectsMappingStatus } from './types';

const PROJECT_MAPPING_SUPPORT_DOC_URL =
    'https://support.atlassian.com/jira-service-management-cloud/docs/link-service-projects-to-a-help-center/';

interface StaticContentProps {
    isReadOnlyView: boolean;
}
const StaticContent: React.FC<StaticContentProps> = ({ isReadOnlyView }: StaticContentProps) => {
    const { formatMessage } = useIntl();
    const helpCenterManageProjectsLink = (chunks: ReactNode) => (
        <Link
            href={PROJECT_MAPPING_SUPPORT_DOC_URL}
            actionSubjectId="manageProjectsLink"
            target="_blank"
            rel="noreferrer"
        >
            {chunks}
        </Link>
    );

    return (
        <>
            <Heading size="large">{formatMessage(messages.manageProjectsPageTitle)}</Heading>
            {isReadOnlyView ? (
                <Box>
                    <Text>
                        {formatMessage(
                            isVaRebrandToVsaEnabled()
                                ? messages.manageProjectsPageDescriptionForDefaultHC
                                : messages.manageProjectsPageDescriptionForDefaultHCOld,
                            {
                                link: helpCenterManageProjectsLink,
                            }
                        )}
                    </Text>
                </Box>
            ) : (
                <Text>
                    {formatMessage(messages.manageProjectsPageDescription, {
                        link: helpCenterManageProjectsLink,
                    })}
                </Text>
            )}
        </>
    );
};

interface HelpCenterManageProjectsComponentProps {
    setShowSkeletonForStaticContent: (value: boolean) => void;
    isReadOnlyView: boolean;
}

const HelpCenterManageProjectsComponent: React.FC<HelpCenterManageProjectsComponentProps> = ({
    setShowSkeletonForStaticContent,
    isReadOnlyView,
}: HelpCenterManageProjectsComponentProps) => {
    const [helpCenterMappingStatus, setHelpCenterMappingStatus] = React.useState<ProjectsMappingStatus>(
        ProjectsMappingStatus.LINKED
    );
    const helpCenterAri = useHelpCenterAri();
    const data = useLazyLoadQuery<manageProjects_GetProjectsQuery>(
        graphql`
            query manageProjects_GetProjectsQuery(
                $cloudId: ID!
                $helpCenterId: ID!
                $helpCenterARI: ID!
                $helpCenterMappingStatus: JiraProjectsHelpCenterMappingStatus!
            ) {
                jira {
                    jiraProjectsMappedToHelpCenter(
                        cloudId: $cloudId
                        filter: {
                            helpCenterId: $helpCenterId
                            helpCenterMappingStatus: $helpCenterMappingStatus
                            helpCenterARI: $helpCenterARI
                        }
                    ) {
                        ...projectsListFragment
                    }
                }
                helpCenter(cloudId: $cloudId) {
                    helpCenterById(helpCenterAri: $helpCenterARI) @optIn(to: ["HelpCenterAggBeta"]) {
                        ...syncProjectsToggleFragment
                    }
                }
            }
        `,
        {
            helpCenterMappingStatus,
            helpCenterId: '',
            helpCenterARI: helpCenterAri,
            cloudId: getCloudId(),
        }
    );
    const { formatMessage } = useIntl();

    const [searchQuery, setSearchQuery] = React.useState<string>('');

    const onInputChange = (value: string): void => {
        setSearchQuery(value);
    };

    const debouncedOnChange = debounce(onInputChange, USER_TYPING_DEBOUNCE_TIME);

    const onTextFieldChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            const value = event.target.value;
            debouncedOnChange(value);
        },
        [debouncedOnChange]
    );

    const statusTextMap: StatusTextMap = {
        [ProjectsMappingStatus.LINKED]: formatMessage(messages.linkedFilterLabel),
        [ProjectsMappingStatus.UNLINKED]: formatMessage(messages.unlinkedFilterLabel),
    };

    if (!data?.jira?.jiraProjectsMappedToHelpCenter) {
        return null;
    }

    if (!data?.helpCenter?.helpCenterById) {
        return null;
    }

    return (
        <ContextualAnalyticsData sourceName="helpCenterManageProjects" sourceType={SCREEN}>
            {!isReadOnlyView && (
                <Box>
                    <Inline alignBlock="center">
                        <Text weight="medium" color="color.text.subtle">
                            {formatMessage(messages.manageProjectPageToggleForDefaultLinking)}
                        </Text>
                        <SyncProjectsToggle syncProjectsToggleFragmentKey={data.helpCenter?.helpCenterById} />
                    </Inline>
                </Box>
            )}
            <Box paddingBlock="space.200">
                <Inline>
                    <Box xcss={searchInputStyles}>
                        <Textfield
                            name="search"
                            autoComplete="off"
                            isCompact
                            elemAfterInput={
                                <Box paddingInlineEnd="space.100">
                                    <Search size="small" label="search icon" />
                                </Box>
                            }
                            defaultValue={searchQuery}
                            onChange={onTextFieldChange}
                            placeholder={formatMessage(messages.searchProjectsPlaceholder)}
                        />
                    </Box>
                    {!isReadOnlyView && (
                        <AnalyticsContext data={{ actionSubjectId: 'projectMappingMenu' }}>
                            <Box xcss={dropdownContainerStyles}>
                                <DropdownMenu trigger={statusTextMap[helpCenterMappingStatus]} shouldFitContainer>
                                    <DropdownItemGroup>
                                        <AnalyticsContext data={{ actionSubjectId: 'projectMappingLinkedMenuItem' }}>
                                            <DropdownItem
                                                onClick={() => {
                                                    setShowSkeletonForStaticContent(false);
                                                    setHelpCenterMappingStatus(ProjectsMappingStatus.LINKED);
                                                }}
                                            >
                                                {statusTextMap[ProjectsMappingStatus.LINKED]}
                                            </DropdownItem>
                                        </AnalyticsContext>
                                        <AnalyticsContext data={{ actionSubjectId: 'projectMappingUnLinkedMenuItem' }}>
                                            <DropdownItem
                                                onClick={() => {
                                                    setShowSkeletonForStaticContent(false);
                                                    setHelpCenterMappingStatus(ProjectsMappingStatus.UNLINKED);
                                                }}
                                            >
                                                {statusTextMap[ProjectsMappingStatus.UNLINKED]}
                                            </DropdownItem>
                                        </AnalyticsContext>
                                    </DropdownItemGroup>
                                </DropdownMenu>
                            </Box>
                        </AnalyticsContext>
                    )}
                </Inline>
            </Box>
            <ProjectsList
                isReadOnlyView={isReadOnlyView}
                projectsListFragmentKey={data?.jira?.jiraProjectsMappedToHelpCenter}
                selection={helpCenterMappingStatus}
                searchQuery={searchQuery}
            />
            <ExperienceSuccess experience={manageProjectsExperience} />
            <FireScreenEventOnMount />
        </ContextualAnalyticsData>
    );
};

export const HelpCenterManageProjects: ComponentType = () => {
    di(isDefaultHelpCenter, getIsMultiHCEnabled, useHelpCenterAri, AdminManagementLayout, SyncProjectsToggle);
    useUFOComponentExperience(manageProjectsExperience);
    const helpCenterAri = useHelpCenterAri();
    const data = useLazyLoadQuery<manageProjectsQuery>(
        graphql`
            query manageProjectsQuery($cloudId: ID!, $helpCenterAri: ID!) {
                helpCenter(cloudId: $cloudId) {
                    helpCenterById(helpCenterAri: $helpCenterAri) @optIn(to: ["HelpCenterAggBeta"]) {
                        ... on HelpCenter {
                            name {
                                default
                            }
                            slug @optIn(to: "HelpCenterSlugTest")
                        }
                    }
                }
            }
        `,
        { helpCenterAri, cloudId: getCloudId() }
    );
    const helpCenterName = data.helpCenter?.helpCenterById?.name?.default || '';
    const slug = data.helpCenter?.helpCenterById?.slug;
    // This state variable handles whether to show loader for static data when there is a fetch call
    const [showSkeletonForStaticContent, setShowSkeletonForStaticContent] = useState(true);
    const { formatMessage } = useIntl();
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const isDefaultHC = isMoveToManagementRouterEnabled() ? !slug : isDefaultHelpCenter();
    const isReadOnlyView = !getIsMultiHCEnabled() || isDefaultHC;

    const onError = (error: Error | FetchError) => {
        if (!isFetchNetworkOrClientError(error as FetchError)) {
            manageProjectsExperience
                .failure({
                    metadata: {
                        error: error?.message,
                        statusCode: (error as FetchError)?.status,
                    },
                })
                .then(noop)
                .catch(noop);
            //TODO: Add helpCenterMappingStatus
            createAnalyticsEvent({
                analyticsType: TRACK_EVENT_TYPE,
                action: 'fetch_failed',
                actionSubject: 'project',
                errorMessage: error?.message,
                errorStatus: (error as FetchError)?.status,
            }).fire();
        }
    };

    const renderError = () => {
        return (
            <EmptyState
                header={formatMessage(messages.errorTitle)}
                description={formatMessage(messages.errorDescription)}
                imageUrl={ErrorScreen}
            />
        );
    };
    return (
        <AdminManagementLayout featureName="Helpcenter manage projects">
            <ErrorBoundary
                packageName={ScreenName.MANAGE_PROJECTS}
                id={'help-center-manage-projects'}
                renderError={renderError}
                onError={onError}
            >
                <Stack space="space.300">
                    {!showSkeletonForStaticContent && (
                        <SettingsBreadcrumbs helpCenterName={helpCenterName} slug={slug} />
                    )}
                    {!showSkeletonForStaticContent && <StaticContent isReadOnlyView={isReadOnlyView} />}
                    <Suspense fallback={<ManageProjectsLayoutSkeleton staticContent={showSkeletonForStaticContent} />}>
                        {showSkeletonForStaticContent && (
                            <SettingsBreadcrumbs helpCenterName={helpCenterName} slug={slug} />
                        )}
                        {showSkeletonForStaticContent && <StaticContent isReadOnlyView={isReadOnlyView} />}
                        <HelpCenterManageProjectsComponent
                            setShowSkeletonForStaticContent={setShowSkeletonForStaticContent}
                            isReadOnlyView={isReadOnlyView}
                        />
                    </Suspense>
                </Stack>
            </ErrorBoundary>
        </AdminManagementLayout>
    );
};

const searchInputStyles = xcss({
    width: '288px',
    marginRight: 'space.200',
});

const dropdownContainerStyles = xcss({
    zIndex: 'modal',
});
