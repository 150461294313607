import React from 'react';
import { HELP_CENTERS_MANAGEMENT_BASE_PATH } from '@helpCenter/view/common/constants';
import { isMoveToManagementRouterEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import Breadcrumbs from '@atlaskit/breadcrumbs';
import { Box } from '@atlaskit/primitives';
import { BreadCrumb } from '@atlassian/help-center-common-component/bread-crumbs/bread-crumb-dumb';
import messages from './messages';

interface SettingsBreadcrumbsProps {
    helpCenterName: string;
    slug?: string | null;
}

const SettingsBreadcrumbs = ({ helpCenterName, slug }: SettingsBreadcrumbsProps) => {
    const { formatMessage } = useIntl();
    const rootBreadcrumbUrl = isMoveToManagementRouterEnabled()
        ? '/'
        : `${window.location.origin}${HELP_CENTERS_MANAGEMENT_BASE_PATH}`;

    const getHelpcenterBreadcrumbUrl = () => {
        if (isMoveToManagementRouterEnabled()) {
            return !slug
                ? `${window.location.origin}/servicedesk/customer`
                : `${window.location.origin}/helpcenter/${slug}`;
        }
        return '/';
    };

    return (
        <Box paddingBlockStart="space.400">
            <Breadcrumbs label={formatMessage(messages.breadcrumbsLabel)}>
                <BreadCrumb
                    isFirstCrumb
                    to={rootBreadcrumbUrl}
                    name={formatMessage(messages.settingsPageBreadcrumbItem)}
                    actionSubjectId="helpCenterBreadcrumb"
                >
                    <BreadCrumb
                        to={getHelpcenterBreadcrumbUrl()}
                        name={helpCenterName}
                        actionSubjectId="helpDeskBreadcrumb"
                    ></BreadCrumb>
                </BreadCrumb>
            </Breadcrumbs>
        </Box>
    );
};

export default SettingsBreadcrumbs;
