import type React from 'react';

import type { UFOExperience } from '@atlassian/ufo';
import { useUFOComponentExperience } from '@atlassian/ufo';

export interface UFOProductExperienceProps {
    ufoExperience: UFOExperience;
}

export const ProductExperienceStart: React.FunctionComponent<UFOProductExperienceProps> = (props) => {
    const { ufoExperience } = props;
    useUFOComponentExperience(ufoExperience);
    return null;
};

export const externalLinksProductExperienceId = 'service-management.help-center.external-resources';
export const yourApprovalExperienceId = 'service-management.help-center.your-approval';
export const approvalStatusSectionExperienceId = 'service-management.help-center.approval-status-section';
export const publishLayoutExperienceId = 'service-management.help-center.publish.layout';
export const topicsReorderExperienceId = 'service-management.help-center.topics.reorder';
export const helpCenterTopicsProductExperienceId = 'service-management.help-center.topics';
export const helpCenterViewHomePageExperienceId = 'service-management.help-center.view.homepage';
export const helpCenterTopicEditExperienceId = 'service-management.help-center.topic.edit';
export const helpCenterTopicViewExperienceId = 'service-management.help-center.topic.view';

export const viewHomepageExperienceId = 'service-management.help-center.advanced.homepage.view';
export const profileExperienceId = 'service-management.help-center.profile.view';
export const updateManageColumnsSidebarExperienceId =
    'service-management.help-center.update.manage-columns-sidebar-view';
export const editHomepageExperienceId = 'service-management.help-center.advanced.homepage.edit';

export const searchExperienceId = 'service-management.help-center.search';
export const inlineSearchExperienceId = 'service-management.help-center.inline-search';

export const requestServiceDeskFromPortalExperienceId = 'service-management.help-center.request-service-desk-cta';

export const helpCenterReportingExperienceId = 'service-management.help-center.help-center-reporting';

export const helpCenterReportingContentGapExperienceId =
    'service-management.help-center.help-center-reporting-content-gap';

export const helpCentersManagementExperienceId = 'service-management.help-center.help-centers-management';
export const manageProjectsExperienceId = 'service-management.help-center.manage-projects';
export const multiHCDropdownExperienceId = 'service-management.help-center.multi-hc-dropdown';
export const avatarMenuExperienceId = 'service-management.help-center.avatar-menu';
export const updateAnnouncementSideBarExperienceId = 'service-management.help-center.update.announcement-sidebar';
export const updateSinglePortalAnnouncementSideBarExperienceId =
    'service-management.help-center.update.single-portal-announcement-sidebar';
export const updateLoginAnnouncementSideBarExperienceId =
    'service-management.help-center.update.login-announcement-sidebar';
export const updateThemeSideBarExperienceId = 'service-management.help-center.update.theme-sidebar';

export const requestCreateEntitlementPickerExperienceId =
    'service-management.help-center.request-create.jsm-cs-entitlement-custom-field-load';

export const csmHelpCenterFormPickerExperienceId = 'service-management.help-center.csm-help-center-form-picker';
export const csmHelpCenterLoadFormExperienceId = 'service-management.help-center.csm-help-center-load-form';
