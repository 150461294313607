import type { HCBrandingResponse, UpdateBrandingBody } from 'rest/help-center-branding';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const UPDATE_HELP_CENTER_PREVIEW_MODEL = 'state.ui.help-center-preview.UPDATE_HELP_CENTER_PREVIEW_MODEL';
export const CLEAR_HELP_CENTER_PREVIEW_MODEL = 'state.ui.help-center-preview.CLEAR_HELP_CENTER_PREVIEW_MODEL';
export const EDITING_HELP_CENTER = 'state.ui.help-center-preview.EDITING_HELP_CENTER';
export const UPDATE_HELP_CENTER_BRANDING = 'state.ui.help-center-preview.UPDATE_HELP_CENTER_BRANDING';
export const UPDATE_HELP_CENTER_BRANDING_MUTATION_SUCCESS =
    'state.ui.help-center-preview.UPDATE_HELP_CENTER_BRANDING_MUTATION_SUCCESS';
export const UPDATE_HELP_CENTER_BRANDING_MUTATION_FAILURE =
    'state.ui.help-center-preview.UPDATE_HELP_CENTER_BRANDING_MUTATION_FAILURE';
export const UPDATE_HELP_CENTER_BRANDING_FAILURE = 'state.ui.help-center-preview.UPDATE_HELP_CENTER_BRANDING_FAILURE';
export const UPLOAD_START = 'state.ui.help-center-preview.UPDATE_HELP_CENTER_BRANDING_UPLOAD_START';
export const UPLOAD_END = 'state.ui.help-center-preview.UPDATE_HELP_CENTER_BRANDING_UPLOAD_END';
export const UPDATE_HELP_CENTER_BRANDING_SAVE_START =
    'state.ui.help-center-preview.UPDATE_HELP_CENTER_BRANDING_SAVE_START';

export interface AnalyticsMetaData {
    analyticsSuccessEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}
export interface EditingHelpCenter {
    type: typeof EDITING_HELP_CENTER;
    payload: boolean;
}

export const editingHelpCenter = (isEditing: boolean): EditingHelpCenter => ({
    type: EDITING_HELP_CENTER,
    payload: isEditing,
});

export interface HCPreviewTranslation {
    sharedPortalName?: string;
    helpCenterTitle?: string;
    announcementHeader?: string;
    announcementMessage?: string;
}

export interface HCPreviewUpdate {
    translations?: {
        [key: string]: HCPreviewTranslation;
    };
    helpCenterTitleColor?: string;
    themeColor?: string;
    logoUrl?: string | null;
    logoMediaApiFileId?: string | null;
    helpCenterBannerUrl?: string | null;
    bannerMediaApiFileId?: string | null;
    useDefaultBanner?: boolean;
}

export interface UpdateHCPreviewModelAction {
    payload: HCPreviewUpdate;
    type: typeof UPDATE_HELP_CENTER_PREVIEW_MODEL;
}

export interface ClearHCPreviewModelAction {
    type: typeof CLEAR_HELP_CENTER_PREVIEW_MODEL;
}

export const updateHCPreviewModelAction = (previewPayload: HCPreviewUpdate): UpdateHCPreviewModelAction => ({
    payload: previewPayload,
    type: UPDATE_HELP_CENTER_PREVIEW_MODEL,
});

export const clearHCPreviewModelAction = (): ClearHCPreviewModelAction => ({
    type: CLEAR_HELP_CENTER_PREVIEW_MODEL,
});

export interface UpdateBranding {
    type: typeof UPDATE_HELP_CENTER_BRANDING;
    payload: {
        body: UpdateBrandingBody;
    };
    meta: AnalyticsMetaData;
}

export const updateBranding = (body: UpdateBrandingBody, meta: AnalyticsMetaData = {}): UpdateBranding => ({
    meta,
    type: UPDATE_HELP_CENTER_BRANDING,
    payload: { body },
});

export interface UpdateBrandingMutationSuccess {
    type: typeof UPDATE_HELP_CENTER_BRANDING_MUTATION_SUCCESS;
    meta?: AnalyticsMetaData;
    response: Partial<HCBrandingResponse>;
}

export const updateBrandingMutationSuccess = (
    response: Partial<HCBrandingResponse>,
    meta: AnalyticsMetaData = {}
): UpdateBrandingMutationSuccess => ({
    meta,
    response,
    type: UPDATE_HELP_CENTER_BRANDING_MUTATION_SUCCESS,
});

export interface UpdateBrandingMutationFailure {
    type: typeof UPDATE_HELP_CENTER_BRANDING_MUTATION_FAILURE;
    meta?: AnalyticsMetaData;
    error: Error;
}

export const updateBrandingMutationFailure = (
    error: Error,
    meta: AnalyticsMetaData = {}
): UpdateBrandingMutationFailure => ({
    meta,
    error,
    type: UPDATE_HELP_CENTER_BRANDING_MUTATION_FAILURE,
});

export interface UpdateBrandingSaveStart {
    type: typeof UPDATE_HELP_CENTER_BRANDING_SAVE_START;
}

export const updateBrandingSaveStart = (): UpdateBrandingSaveStart => ({
    type: UPDATE_HELP_CENTER_BRANDING_SAVE_START,
});

export interface UpdateBrandingFailure {
    type: typeof UPDATE_HELP_CENTER_BRANDING_FAILURE;
}

export const updateBrandingFailure = (): UpdateBrandingFailure => ({
    type: UPDATE_HELP_CENTER_BRANDING_FAILURE,
});

export interface UploadStart {
    type: typeof UPLOAD_START;
    payload: {
        fileCollection: string;
    };
}

export const uploadStart = (fileCollection: string): UploadStart => ({
    type: UPLOAD_START,
    payload: {
        fileCollection,
    },
});

export interface UploadEnd {
    type: typeof UPLOAD_END;
    payload: {
        fileCollection: string;
    };
}

export const uploadEnd = (fileCollection: string): UploadEnd => ({
    type: UPLOAD_END,
    payload: {
        fileCollection,
    },
});
