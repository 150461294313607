import React, { useEffect } from 'react';
import { isFetchNetworkOrClientError, type FetchError } from 'util/fetch';
import type { showErrorFlag, showSuccessFlag } from '@helpCenter/state/actions/flags';
import { getCloudId } from '@helpCenter/util/meta';
import ProjectMappingEmpty from 'assets/images/project-mapping-empty.svg';
import EmptySearch from 'assets/images/project-mapping-search-no-match.svg';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { graphql, useFragment } from 'react-relay';
import { Link } from 'react-resource-router';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import { Checkbox } from '@atlaskit/checkbox';
import DynamicTable from '@atlaskit/dynamic-table';
import EmptyState from '@atlaskit/empty-state';
import Lozenge from '@atlaskit/lozenge';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import { TRACK_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { useProjectMapping } from '../../../services/use-project-mapping';
import { CANT_UNLINK_ALL_DIALOG, MANAGE_PROJECT_HEAD_WIDTH, UNLINK_CONFIRMATION_DIALOG } from '../../constants';
import type { JiraProjectsHelpCenterMappingStatus } from '../__generated__/manageProjects_GetProjectsQuery.graphql';
import { LinkUnlinkProject } from '../link-unlink-project';
import actionMessages from '../link-unlink-project/messages';
import { ProjectsMappingMode, ProjectsMappingOperations, ProjectsMappingStatus } from '../types';
import type { projectsListFragment$key } from './__generated__/projectsListFragment.graphql';
import { BulkActionToolbar } from './bulk-action-toolbar';
import messages from './messages';
import { UnlinkAllProjectsWarningModal } from './unlink-all-warning-modal/unlink-all-warning-modal';
import { UnlinkConfirmationModal } from './unlink-confirmation-modal/unlink-confirmation-modal';

export interface Props {
    projectsListFragmentKey: projectsListFragment$key;
    searchQuery: string;
    selection: JiraProjectsHelpCenterMappingStatus;
    isReadOnlyView: boolean;
    showErrorFlag: typeof showErrorFlag;
    showSuccessFlag: typeof showSuccessFlag;
}

interface ProjectSelectionMap {
    [projectId: string]: boolean;
}

export const ROWS_PER_PAGE = 20;

const messagesMap = {
    [ProjectsMappingOperations.LINK]: {
        [ProjectsMappingMode.BULK]: {
            successTitle: actionMessages.bulkLinkSuccessTitle,
            errorTitle: actionMessages.bulkLinkErrorTitle,
        },
        [ProjectsMappingMode.SINGLE]: {
            successTitle: actionMessages.linkSuccessTitle,
            errorTitle: actionMessages.linkErrorTitle,
        },
    },
    [ProjectsMappingOperations.UNLINK]: {
        [ProjectsMappingMode.BULK]: {
            successTitle: actionMessages.bulkUnlinkSuccessTitle,
            errorTitle: actionMessages.bulkUnlinkErrorTitle,
        },
        [ProjectsMappingMode.SINGLE]: {
            successTitle: actionMessages.unlinkSuccessTitle,
            errorTitle: actionMessages.unlinkErrorTitle,
        },
    },
};

const ProjectsList = ({
    projectsListFragmentKey,
    searchQuery,
    selection,
    isReadOnlyView,
    showErrorFlag: showErrorFlagAction,
    showSuccessFlag: showSuccessFlagAction,
}: Props) => {
    di(getCloudId);
    const [isChecked, setIsChecked] = React.useState(false);
    const [isBulkActionLoading, setIsBulkActionLoading] = React.useState(false);
    const [selectedProjectsMap, setSelectedProjectsMap] = React.useState<ProjectSelectionMap>({});
    const [modalVisibility, setModalVisibility] = React.useState({
        [CANT_UNLINK_ALL_DIALOG]: false,
        [UNLINK_CONFIRMATION_DIALOG]: false,
    });
    const { formatMessage } = useIntl();
    const data = useFragment(
        graphql`
            fragment projectsListFragment on JiraProjectConnection {
                edges {
                    node {
                        name
                        projectId
                        avatar {
                            small
                        }
                        lead {
                            name
                            picture
                        }
                        webUrl
                    }
                }
            }
        `,
        projectsListFragmentKey
    );
    const { onLinkUnlinkProjects } = useProjectMapping();
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const selectedProjectsCount = Object.values(selectedProjectsMap).filter(Boolean).length;

    const disableSingleProjectActions = selectedProjectsCount > 0;
    const projects = data.edges || [];
    const handleProjectMapping = (
        selectedProjectIds: string[],
        operationType: ProjectsMappingOperations,
        mappingStatus: JiraProjectsHelpCenterMappingStatus
    ) => {
        const getProjectName = () =>
            projects.find((project) => project?.node?.projectId === selectedProjectIds[0])?.node?.name;
        const mode = selectedProjectIds.length > 1 ? ProjectsMappingMode.BULK : ProjectsMappingMode.SINGLE;
        setIsBulkActionLoading(true);
        onLinkUnlinkProjects(selectedProjectIds, operationType, mappingStatus)
            .then(() => {
                createAnalyticsEvent({
                    analyticsType: TRACK_EVENT_TYPE,
                    action: 'map',
                    actionSubject: 'project',
                    bulkOperation: true,
                    mapStatus: operationType,
                    projectCount: selectedProjectIds.length,
                }).fire();
                resetBulkSelection();
                showSuccessFlagAction(
                    messagesMap[operationType][mode].successTitle,
                    mode === ProjectsMappingMode.SINGLE
                        ? { projectName: getProjectName() }
                        : { number: selectedProjectIds.length }
                );
            })
            .catch((error: FetchError) => {
                const { message, status } = error;
                showErrorFlagAction(
                    messagesMap[operationType][mode].errorTitle,
                    actionMessages.genericErrorDescription,
                    [],
                    mode === ProjectsMappingMode.SINGLE ? { projectName: getProjectName() } : undefined
                );
                if (!isFetchNetworkOrClientError(error)) {
                    createAnalyticsEvent({
                        analyticsType: TRACK_EVENT_TYPE,
                        action: 'map_failed',
                        actionSubject: 'project',
                        bulkOperation: true,
                        mapStatus: operationType,
                        projectCount: selectedProjectIds.length,
                        errorMessage: message,
                        errorStatus: status,
                    }).fire();
                }
            })
            .finally(() => {
                setIsBulkActionLoading(false);
                setModalVisibility({ ...modalVisibility, [UNLINK_CONFIRMATION_DIALOG]: false });
            });
    };

    const onBulkActionClick = () => {
        const selectedProjectIds = Object.keys(selectedProjectsMap).filter(
            (projectId) => selectedProjectsMap[projectId]
        );
        const operation =
            selection === ProjectsMappingStatus.LINKED
                ? ProjectsMappingOperations.UNLINK
                : ProjectsMappingOperations.LINK;

        if (selectedProjectIds.length === 0) {
            return;
        }

        if (operation === ProjectsMappingOperations.UNLINK && selectedProjectIds.length === projects.length) {
            setModalVisibility({ ...modalVisibility, [CANT_UNLINK_ALL_DIALOG]: true });
            return;
        }

        if (operation === ProjectsMappingOperations.UNLINK) {
            setModalVisibility({ ...modalVisibility, [UNLINK_CONFIRMATION_DIALOG]: true });
            return;
        }
        handleProjectMapping(selectedProjectIds, ProjectsMappingOperations.LINK, selection);
    };

    const onUnlinkDialogButtonClick = () => {
        const selectedProjectIds = Object.keys(selectedProjectsMap).filter(
            (projectId) => selectedProjectsMap[projectId]
        );
        setIsBulkActionLoading(true);
        handleProjectMapping(selectedProjectIds, ProjectsMappingOperations.UNLINK, selection);
    };

    const isIndeterminate = !!(selectedProjectsCount > 0 && selectedProjectsCount < projects.length);

    const filteredProjects = projects.filter((project) =>
        project?.node?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const resetBulkSelection = () => {
        setIsChecked(false);
        setSelectedProjectsMap({});
    };

    // whenever the selection changes, reset the selected projects
    useEffect(() => {
        resetBulkSelection();
    }, [selection]);

    useEffect(() => {
        // If all projects are selected one by one, check the select all checkbox
        if (selectedProjectsCount === filteredProjects.length) {
            setIsChecked(true);
        }

        // If all projects are unselected one by one, uncheck the select all checkbox
        if (selectedProjectsCount === 0) {
            setIsChecked(false);
        }
    }, [selectedProjectsCount, filteredProjects.length]);

    const onChangeHandler = () => {
        const selectionMap: ProjectSelectionMap = {};
        setSelectedProjectsMap(
            isChecked
                ? selectionMap
                : filteredProjects.reduce((acc, project) => {
                      if (!project?.node?.projectId) {
                          return acc;
                      }
                      acc[project?.node?.projectId] = true;
                      return acc;
                  }, selectionMap)
        );
        setIsChecked(!isChecked);
    };

    const head = {
        cells: [
            ...(isReadOnlyView
                ? []
                : [
                      {
                          key: 'select-all',
                          content: (
                              <Checkbox
                                  aria-checked={isChecked}
                                  isChecked={isChecked}
                                  isIndeterminate={isIndeterminate}
                                  onChange={onChangeHandler}
                                  aria-label={formatMessage(messages.selectAllProjectsCheckboxLabel)}
                                  name="select-all-checkbox"
                              />
                          ),
                          width: MANAGE_PROJECT_HEAD_WIDTH['select-all'],
                      },
                  ]),
            {
                key: 'project-name',
                content: 'Project',
                shouldTruncate: true,
                isSortable: !isChecked ? true : undefined,
                width: MANAGE_PROJECT_HEAD_WIDTH['project-name'],
            },
            {
                key: 'lead-name',
                content: 'Lead',
                shouldTruncate: true,
                isSortable: !isChecked ? true : undefined,
                width: MANAGE_PROJECT_HEAD_WIDTH['lead-name'],
            },
            ...(isReadOnlyView
                ? []
                : [
                      {
                          key: 'status',
                          content: 'Status',
                          width: MANAGE_PROJECT_HEAD_WIDTH.status,
                      },
                      {
                          key: 'action-button',
                          content: 'Actions',
                          width: MANAGE_PROJECT_HEAD_WIDTH['action-button'],
                      },
                  ]),
        ],
    };

    const toggleProjectSelect = (projectId: string) => {
        setSelectedProjectsMap((prevState) => ({
            ...prevState,
            [projectId]: !prevState[projectId],
        }));
    };

    const rows = filteredProjects.map((project) => ({
        key: project?.node?.projectId || project?.node?.name,
        isHighlighted: selectedProjectsMap[project?.node?.projectId || ''],
        cells: [
            ...(isReadOnlyView
                ? []
                : [
                      {
                          content: (
                              <Checkbox
                                  isChecked={!!selectedProjectsMap[project?.node?.projectId || '']}
                                  onChange={() => toggleProjectSelect(project?.node?.projectId || '')}
                                  name="select-checkbox"
                                  aria-label={formatMessage(messages.selectProjectCheckboxLabel)}
                              />
                          ),
                      },
                  ]),
            {
                key: project?.node?.name,
                content: (
                    <Link target="_blank" href={project?.node?.webUrl || '#'}>
                        <Inline alignBlock="center">
                            <Box paddingBlock="space.050" paddingInlineEnd="space.100">
                                {project?.node?.avatar?.small && (
                                    <Avatar
                                        size="small"
                                        appearance="square"
                                        src={project?.node?.avatar?.small}
                                        name={project?.node?.avatar?.small}
                                    ></Avatar>
                                )}
                            </Box>
                            <Text>{project?.node?.name}</Text>
                        </Inline>
                    </Link>
                ),
            },
            {
                key: project?.node?.lead?.name,
                content: (
                    <Inline alignBlock="center">
                        <Box paddingInlineEnd="space.100">
                            <Avatar
                                size="small"
                                appearance="circle"
                                src={project?.node?.lead?.picture}
                                name={project?.node?.lead?.picture}
                            ></Avatar>
                        </Box>
                        <Text>{project?.node?.lead?.name}</Text>
                    </Inline>
                ),
            },
            ...(isReadOnlyView
                ? []
                : [
                      {
                          content:
                              selection === ProjectsMappingStatus.LINKED ? (
                                  <Lozenge appearance="success">
                                      {formatMessage(messages.projectsListLinkedLozengeLabel)}
                                  </Lozenge>
                              ) : (
                                  <Lozenge>{formatMessage(messages.projectsListUnlinkedLozengeLabel)}</Lozenge>
                              ),
                      },
                      {
                          content: (
                              <LinkUnlinkProject
                                  isDisabled={disableSingleProjectActions}
                                  projectId={project?.node?.projectId || ''}
                                  projectName={project?.node?.name || ''}
                                  selection={selection}
                                  isLastProject={projects.length === 1}
                                  onLinkUnlinkProjects={onLinkUnlinkProjects}
                              />
                          ),
                      },
                  ]),
        ],
    }));

    // If user searches for a project that doesn't exist
    if (rows.length === 0 && searchQuery.trim() !== '') {
        return (
            <EmptyState
                header={formatMessage(messages.projectsListEmptySearchTitle)}
                description={formatMessage(messages.projectsListEmptySearchDescription)}
                imageUrl={EmptySearch}
            />
        );
    }
    // If no projects are linked to the help center
    if (rows.length === 0 && selection === ProjectsMappingStatus.UNLINKED) {
        return (
            <EmptyState
                header={formatMessage(messages.projectsListEmptyStateTitle)}
                description={formatMessage(messages.projectsListEmptyStateDescription)}
                imageUrl={ProjectMappingEmpty}
            />
        );
    }
    return (
        <Box xcss={tableStyles}>
            <BulkActionToolbar
                selectedProjectsCount={selectedProjectsCount}
                selection={selection}
                onBulkActionClick={onBulkActionClick}
                isLoading={isBulkActionLoading}
            />
            <DynamicTable
                head={head}
                rows={rows}
                rowsPerPage={ROWS_PER_PAGE}
                defaultPage={1}
                isFixedSize
                defaultSortKey="project-name"
                defaultSortOrder="ASC"
            />
            <UnlinkConfirmationModal
                isOpen={modalVisibility[UNLINK_CONFIRMATION_DIALOG]}
                onClose={() => setModalVisibility({ ...modalVisibility, [UNLINK_CONFIRMATION_DIALOG]: false })}
                onUnlink={onUnlinkDialogButtonClick}
                isLoading={modalVisibility[UNLINK_CONFIRMATION_DIALOG] && isBulkActionLoading}
                numberOfProjects={selectedProjectsCount}
            />
            <UnlinkAllProjectsWarningModal
                isOpen={modalVisibility[CANT_UNLINK_ALL_DIALOG]}
                onClose={() => setModalVisibility({ ...modalVisibility, [CANT_UNLINK_ALL_DIALOG]: false })}
            />
        </Box>
    );
};

const tableStyles = xcss({
    position: 'relative',
    // @ts-expect-error @atlaskit/ui-styling-standard/no-nested-selectors
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
    th: { height: '100%', verticalAlign: 'middle' },
    marginBottom: 'space.100',
});

export default ProjectsList;
