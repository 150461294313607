import { defineMessages } from 'react-intl-next';

export default defineMessages({
    helpfulPageHeading: {
        id: 'ai-answer.feedback-form.helpful-page-heading',
        defaultMessage: 'Awesome!',
        description: 'Heading for the feedback form helpful page',
    },
    helpfulPageSubHeading: {
        id: 'ai-answer.feedback-form.helpful-page-sub-heading',
        defaultMessage: 'Glad we could help.',
        description: 'Subheading for the feedback form helpful page',
    },
    nonHelpfulPageHeading: {
        id: 'ai-answer.feedback-form.non-helpful-page-heading',
        defaultMessage: 'Sorry, it wasn’t helpful.',
        description: 'Heading for the feedback form non-helpful page',
    },
    nonHelpfulPageSubHeading: {
        id: 'ai-answer.feedback-form.non-helpful-page-sub-heading',
        defaultMessage: "We're working on your request.",
        description: 'Subheading for the feedback form non-helpful page',
    },
    additionalFeedbackCTALabel: {
        id: 'ai-answer.feedback-form.additional-feedback-label',
        defaultMessage: 'Give additional feedback',
        description: 'Label for the additional feedback button',
    },
    feedbackTitle: {
        id: 'ai-answer.feedback-form.feedback-title',
        defaultMessage: 'Help us improve',
        description: 'Feedback dialog title',
    },
    sendFeedbackLabel: {
        defaultMessage: 'Send Feedback',
        description: 'Feedback dialog Submit button label',
        id: 'ai-answer.feedback-form.feedback-submit-button-label',
    },
    cancelLabel: {
        defaultMessage: 'Cancel',
        description: 'Feedback dialog cancel button label',
        id: 'ai-answer.feedback-form.feedback-cancel-button-label',
    },
    contactLabel: {
        defaultMessage: 'Contact me for more details about this feedback',
        description: 'Feedback dialog can contact label',
        id: 'ai-answer.feedback-form.feedback-can-contact-label',
    },
    summary: {
        defaultMessage: 'Summary',
        description: 'Feedback dialog summary label',
        id: 'ai-answer.feedback-form.feedback-summary',
    },
    subHeading: {
        defaultMessage: 'Required fields are marked with an asterisk',
        description: 'Feedback dialog sub heading',
        id: 'ai-answer.feedback-form.feedback-sub-heading',
    },
    genericErrorMessage: {
        defaultMessage: 'Looks like we’re having trouble retrieving some information, please refresh the page.',
        description: 'Generic error when something could not be loaded',
        id: 'ai-answer.feedback-form.generic-heading',
    },
    genericRedirectMessage: {
        defaultMessage: 'Go to the portal.',
        description: 'Generic redirect link message when something could not be loaded',
        id: 'ai-answer.feedback-form.generic-redirect-message',
    },
});
