import { useCallback } from 'react';
import { getCloudId } from '@helpCenter/util/meta';
import { graphql, useMutation } from 'react-relay';
import type { RecordSourceProxy, RecordSourceSelectorProxy } from 'relay-runtime';
import { useHelpCenterAri } from '../../hooks/use-help-center-ari';
import type { JiraProjectsHelpCenterMappingStatus } from '../../ui/manage-projects/__generated__/manageProjects_GetProjectsQuery.graphql';
import type {
    HelpCenterProjectMappingOperationType,
    useProjectMappingLinkUnlinkMutation,
    useProjectMappingLinkUnlinkMutation$data,
} from './__generated__/useProjectMappingLinkUnlinkMutation.graphql';

export const useProjectMapping = () => {
    const cloudId = getCloudId();
    const helpCenterAri = useHelpCenterAri();
    const [linkUnlinkProject] = useMutation<useProjectMappingLinkUnlinkMutation>(graphql`
        mutation useProjectMappingLinkUnlinkMutation($cloudId: ID!, $input: HelpCenterProjectMappingUpdateInput!) {
            helpCenter(cloudId: $cloudId) {
                updateProjectMapping(input: $input) {
                    success
                    errors {
                        message
                    }
                }
            }
        }
    `);

    const removeSelectedProjects = useCallback(
        (
            store: RecordSourceSelectorProxy | RecordSourceProxy,
            selection: JiraProjectsHelpCenterMappingStatus,
            projectIds: string[]
        ) => {
            const root = store.getRoot();
            const jiraRoot = store.getRoot().getLinkedRecord('jira');
            const connection = root?.getLinkedRecord('jira')?.getLinkedRecord('jiraProjectsMappedToHelpCenter', {
                cloudId: getCloudId(),
                filter: { helpCenterId: '', helpCenterARI: helpCenterAri, helpCenterMappingStatus: selection },
            });
            if (connection) {
                const connectionEdges = connection?.getLinkedRecords('edges');
                if (connectionEdges) {
                    const newEdges = connectionEdges.filter(
                        (edge) => !projectIds.includes(edge?.getLinkedRecord('node')?.getValue('projectId') as string)
                    );
                    connection?.setLinkedRecords(newEdges, 'edges');
                }
            }
            if (jiraRoot) {
                // this is required so that switching between linked and unlinked projects makes a fresh query
                jiraRoot.invalidateRecord();
            }
        },
        [helpCenterAri]
    );

    const onLinkUnlinkProjects = (
        projectIds: string[],
        operationType: HelpCenterProjectMappingOperationType,
        selection: JiraProjectsHelpCenterMappingStatus
    ) => {
        return new Promise<useProjectMappingLinkUnlinkMutation$data>((resolve, reject) =>
            linkUnlinkProject({
                variables: {
                    cloudId,
                    input: {
                        helpCenterAri,
                        projectIds,
                        operationType,
                    },
                },
                updater: (store, response) => {
                    if (response?.helpCenter?.updateProjectMapping?.success) {
                        removeSelectedProjects(store, selection, projectIds);
                    }
                },
                onCompleted: (response) => {
                    if (response.helpCenter?.updateProjectMapping?.success) {
                        resolve(response);
                    }
                    const errors = response.helpCenter?.updateProjectMapping?.errors;
                    const error = errors && errors.length > 0 ? errors[0] : new Error('Unknown error');
                    return reject(error);
                },
                onError: (error) => {
                    reject(error);
                },
            })
        );
    };
    return { onLinkUnlinkProjects };
};
