import { format as dateFnsFormat, isValid as isValidDate } from 'date-fns';
import { isRichTextAreaEnabled } from 'feature-flags';
import type { Field } from 'rest/request-create';
import type { NestedFieldValueType } from '@atlassian/help-center-common-component/fields/types';
import type {
    AssetsPickerState,
    AttachmentState,
    CascadingSelectState,
    CheckboxGroupState,
    CheckboxItem,
    CmdbObjectPickerState,
    DateState,
    EntitlementPickerState,
    FieldState,
    LabelPickerState,
    MultiSelectState,
    MultiServicePickerState,
    MultiUserPickerState,
    OrganisationPickerState,
    RadioGroupState,
    RadioItem,
    ReporterState,
    SelectState,
    TextAreaState,
    TextFieldState,
    UnknownState,
    UserPickerState,
} from 'state/persisted/request-create/types';

const MAX_CMDB_OBJECTS_PER_FIELD = 20;
const MAX_AFFECTED_SERVICES_PER_FIELD = 20;

export const toFieldStates = (fields: Field[]): FieldState[] => {
    return fields.map((field) => {
        const fieldType = field.fieldType;
        switch (fieldType) {
            case 'text':
                return toTextFieldState(field);
            case 'textarea':
                return toTextAreaState(field);
            case 'checkbox':
                return toCheckboxGroupState(field);
            case 'radio':
                return toRadioGroupState(field);
            case 'date':
            case 'duedate':
                return toDateState(field, fieldType);
            case 'datetime':
                return toDateTimeState(field);
            case 'attachment':
                return toAttachmentState(field);
            case 'reporter':
                return toReporterState(field);
            case 'userpicker':
                return toUserPickerState(field);
            case 'multiuserpicker':
                return toMultiUserPickerState(field);
            case 'labelpicker':
                return toLabelPickerState(field);
            case 'select':
                return toSelectState(field);
            case 'multiselect':
                return toMultiSelectState(field);
            case 'organisationpicker':
                return toOrganisationState(field);
            case 'assetspicker':
                return toAssetsState(field);
            case 'multiservicepicker':
                return toMultiServicePickerState(field);
            case 'cascadingselect':
                return toCascadingSelect(field);
            case 'cmdbobjectpicker':
                return toCmdbObjectPickerState(field);
            case 'entitlementpicker':
                return toEntitlementPickerState(field);
            default:
                return toUnknownState(field);
        }
    });
};

/**
 * Parses defaultObjectAsJson and converts to an object if it can be parsed
 */
const getDefaultObjectAsJsonFromField = (field: Field) => {
    const { defaultObjectAsJson } = field;
    let defaultObj;

    try {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        defaultObj = defaultObjectAsJson && JSON.parse(defaultObjectAsJson);
    } catch (e) {
        // Swallow the error here
    }

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return defaultObj;
};

// This extracts all the common field properties, normally the only difference between fields are
// type and value
const extractCommonFieldProperties = (field: Field) => ({
    id: field.fieldId,
    displayed: field.displayed,
    label: field.label,
    required: field.required,
    description: field.descriptionHtml,
});

const toTextFieldState = (field: Field): TextFieldState => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { defaultObject } = field;
    const baseFieldProperties = extractCommonFieldProperties(field);
    return {
        ...baseFieldProperties,
        type: 'text',
        value: defaultObject ? (defaultObject as string) : '',
    };
};

const toTextAreaState = (field: Field): TextAreaState => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { defaultObject, rendererType, fieldConfigId } = field;
    const baseFieldProperties = extractCommonFieldProperties(field);
    const emptyDefaultValue = isRichTextAreaEnabled() && rendererType === 'atlassian-wiki-renderer' ? undefined : '';
    return {
        ...baseFieldProperties,
        rendererType,
        fieldConfigId,
        type: 'textarea',
        value: defaultObject ? (defaultObject as string) : emptyDefaultValue,
    };
};

const toCheckboxGroupState = (field: Field): CheckboxGroupState => {
    const baseFieldProperties = extractCommonFieldProperties(field);
    const fieldValues = field.values || [];
    const checkboxItems: CheckboxItem[] = fieldValues.map((fieldValue) => ({
        label: fieldValue.label,
        value: fieldValue.value,
        checked: fieldValue.selected,
    }));
    const checkedItems = checkboxItems.filter((item) => !!item.checked);
    const value = checkedItems.map((item) => item.value);

    return {
        ...baseFieldProperties,
        value,
        type: 'checkbox',
        items: checkboxItems,
    };
};

const toRadioGroupState = (field: Field): RadioGroupState => {
    const baseFieldProperties = extractCommonFieldProperties(field);
    const fieldValues = field.values || [];
    const radioGroupItems: RadioItem[] = fieldValues.map((item) => ({
        name: field.fieldId,
        label: item.label,
        value: item.value,
        selected: item.selected,
    }));
    const selectedItem = radioGroupItems.find((item) => !!item.selected);
    const value = selectedItem ? selectedItem.value : '';

    return {
        ...baseFieldProperties,
        value,
        items: radioGroupItems,
        type: 'radio',
    };
};

const IsoDateFormat = /^\d{4}-\d{2}-\d{2}$/;

const normalizeDateTimeFromJson = (field: Field, isoFormat: string): string | undefined => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let defaultDate: string = getDefaultObjectAsJsonFromField(field);
    if (!defaultDate || !isValidDate(new Date(defaultDate))) {
        // This needs fallback to undefined so the datetime picker sets todays date
        // as the initial screen correctly, else it's 1970.
        return undefined;
    }
    // Fix for the one-day-off issue with new Date()
    // Link: [DateTime Picker One Day Off Issue](https://hello.atlassian.net/wiki/x/zwTjxw)
    if (defaultDate.match(IsoDateFormat)) {
        defaultDate = defaultDate.replace(/-/g, '/');
    }

    return dateFnsFormat(new Date(defaultDate), isoFormat);
};

const toDateState = (field: Field, fieldType: 'date' | 'duedate'): DateState => {
    const baseFieldProperties = extractCommonFieldProperties(field);

    return {
        ...baseFieldProperties,
        defaultDate: normalizeDateTimeFromJson(field, 'yyyy-MM-dd'),
        type: fieldType,
        value: normalizeDateTimeFromJson(field, 'yyyy-MM-dd'),
    };
};

const toDateTimeState = (field: Field): DateState => {
    const baseFieldProperties = extractCommonFieldProperties(field);

    return {
        ...baseFieldProperties,
        defaultDate: normalizeDateTimeFromJson(field, "yyyy-MM-dd'T'HH:mm"),
        type: 'datetime',
        // We don't store timezone date, it's assumed the timezone will be the users timezone set in Jira.
        value: normalizeDateTimeFromJson(field, "yyyy-MM-dd'T'HH:mm"),
    };
};

const toReporterState = (field: Field): ReporterState => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { defaultObject, fieldConfigId } = field;
    const baseFieldProperties = extractCommonFieldProperties(field);
    return {
        ...baseFieldProperties,
        type: 'reporter',
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        defaultReporter: defaultObject,
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        value: defaultObject ? defaultObject.id : undefined,
        fieldConfigId: fieldConfigId ? String(fieldConfigId) : '',
    };
};

const toUserPickerState = (field: Field): UserPickerState => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { defaultObject, fieldConfigId } = field;
    const baseFieldProperties = extractCommonFieldProperties(field);
    return {
        ...baseFieldProperties,
        type: 'userpicker',
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        defaultUser: defaultObject,
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        value: defaultObject ? defaultObject.id : undefined,
        fieldConfigId: fieldConfigId ? String(fieldConfigId) : '',
    };
};

const toMultiUserPickerState = (field: Field): MultiUserPickerState => {
    const defaultList = field.defaultList || [];
    const baseFieldProperties = extractCommonFieldProperties(field);
    return {
        ...baseFieldProperties,
        type: 'multiuserpicker',
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        defaultUsers: defaultList,
        fieldConfigId: field.fieldConfigId ? String(field.fieldConfigId) : '',

        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        value: defaultList.map((u) => u.id).join(','),
    };
};

const toSelectState = (field: Field): SelectState => {
    const selectedValue = field.values ? field.values.find((v) => v.selected) : undefined;
    const value = selectedValue && selectedValue.value;

    return {
        ...extractCommonFieldProperties(field),
        value,
        type: 'select',
        options: field.values || [],
        defaultValue: selectedValue,
    };
};

const toMultiSelectState = (field: Field): MultiSelectState => {
    const selectedValues = field.values ? field.values.filter((v) => v.selected) : [];
    const values = selectedValues.map((v) => v.value);

    return {
        ...extractCommonFieldProperties(field),
        type: 'multiselect',
        options: field.values || [],
        defaultValue: selectedValues,
        value: values,
    };
};

const toLabelPickerState = (field: Field): LabelPickerState => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const value: string[] = field.defaultList || [];

    /**
     * The field id takes the form of customfield_xxxx where xxxx is the custom field id.
     *
     * The field id can also be "label" if it is the system label field.
     * In the case where the field is the system field we will consider the custom field id to be undefiend
     */
    const fieldIdTokens = (field.fieldId || '').toLowerCase().split('customfield_');
    const customFieldId = fieldIdTokens.length > 1 ? fieldIdTokens[1] : undefined;
    return {
        ...extractCommonFieldProperties(field),
        value,
        customFieldId,
        type: 'labelpicker',
        defaultLabels: value.map((labelStr) => ({
            label: labelStr,
            value: labelStr,
        })),
    };
};

const toCascadingSelect = (field: Field): CascadingSelectState => {
    const selectedGroup = field.values && field.values.find((v) => v.selected);
    const selectedValue = selectedGroup && selectedGroup.children.find((v) => v.selected);
    const value: NestedFieldValueType | undefined = selectedGroup && {
        primaryValue: selectedGroup.value,
        children: selectedValue && selectedValue.value,
    };

    const options = field.values
        ? field.values.map((v) => ({
              label: v.label,
              value: v.value,
              children: v.children,
          }))
        : [];

    return {
        ...extractCommonFieldProperties(field),
        options,
        value,
        type: 'cascadingselect',
    };
};

const toOrganisationState = (field: Field): OrganisationPickerState => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { fieldConfigId, defaultObject } = field;

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const shareWithOrgByDefault = field.portalFieldConfiguration?.shareWithOrgByDefault ?? true;

    return {
        ...extractCommonFieldProperties(field),
        fieldConfigId,
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        shareWithOrgByDefault,
        type: 'organisationpicker',
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        value: defaultObject,
    };
};

const toAssetsState = (field: Field): AssetsPickerState => {
    const { fieldConfigId } = field;
    const baseFieldProperties = extractCommonFieldProperties(field);
    return {
        ...baseFieldProperties,
        fieldConfigId,
        type: 'assetspicker',
    };
};

const toMultiServicePickerState = (field: Field): MultiServicePickerState => {
    return {
        ...extractCommonFieldProperties(field),
        type: 'multiservicepicker',
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        limit: field.portalFieldConfiguration?.limit ?? MAX_AFFECTED_SERVICES_PER_FIELD,
    };
};

const toAttachmentState = (field: Field): AttachmentState => {
    const baseFieldProperties = extractCommonFieldProperties(field);
    return {
        ...baseFieldProperties,
        value: [],
        type: 'attachment',
    };
};

const toCmdbObjectPickerState = (field: Field): CmdbObjectPickerState => ({
    ...extractCommonFieldProperties(field),
    hasValidConfig: Boolean(field.portalFieldConfiguration),
    type: 'cmdbobjectpicker',

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    isMulti: field.portalFieldConfiguration ? field.portalFieldConfiguration.isMulti : false,

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    filterAttributes: field.portalFieldConfiguration ? field.portalFieldConfiguration.filterAttributes : [],

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    limit: field.portalFieldConfiguration?.limit ?? MAX_CMDB_OBJECTS_PER_FIELD,
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    placeholderKeys:
        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        field.portalFieldConfiguration?.placeholderKeys ?? undefined,
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    populateDefaultValue:
        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        field.portalFieldConfiguration?.populateDefaultValue ?? false,
});

const toEntitlementPickerState = (field: Field): EntitlementPickerState => {
    return {
        ...extractCommonFieldProperties(field),
        label: field.label,
        type: 'entitlementpicker',
    };
};

const toUnknownState = (field: Field): UnknownState => {
    const baseFieldProperties = extractCommonFieldProperties(field);
    return {
        ...baseFieldProperties,
        type: 'unknown',
    };
};
