/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<10022a54988bd909531d7d82a95f6c5d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type syncProjectsToggleFragment$data = {
  readonly projectMappingData: {
    readonly syncNewProjects: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "syncProjectsToggleFragment";
};
export type syncProjectsToggleFragment$key = {
  readonly " $data"?: syncProjectsToggleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"syncProjectsToggleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "syncProjectsToggleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HelpCenterProjectMappingData",
      "kind": "LinkedField",
      "name": "projectMappingData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "syncNewProjects",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "HelpCenter",
  "abstractKey": null
};

(node as any).hash = "b09d61797a2afad5be65329bfb50cc92";

export default node;
