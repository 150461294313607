import {
    updateLoginAnnouncementsData,
    mapLoginAnnouncementsPayloadToGqlInput,
} from 'util/graphql-relay-connector/login-announcements-update';
import type { ActionsObservable } from 'redux-observable';
import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';
import type { HandleAjaxError } from 'state/actions/flags';
import { handleAjaxError } from 'state/actions/flags';
import type {
    UpdateLoginAnnouncementsModel,
    UpdateLoginAnnouncementsSuccess,
    UpdateLoginAnnouncementsFailure,
    ResetLoginAnnouncementsSettings,
} from 'state/actions/login-announcements';
import {
    UPDATE_LOGIN_ANNOUNCEMENTS_MODEL,
    updateLoginAnnouncementsSuccess,
    updateLoginAnnouncementsFailure,
    resetLoginAnnouncements,
} from 'state/actions/login-announcements';
import { HTTP } from 'view/common/constants';
import { updateLoginAnnouncementSideBarExperience } from 'view/help-center-customize-sidebar/bucket-routes/experiences';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { isNetworkOrClientErrorCode } from '@atlassian/help-center-common-util/error-codes';
import type { ErrorResponse } from 'epics/model/types';

const gqlMutationLoginAnnouncements = (action: UpdateLoginAnnouncementsModel) => {
    const { meta, payload } = action;
    const inputVariables = mapLoginAnnouncementsPayloadToGqlInput(payload);

    if (
        !inputVariables.input.nameTranslations?.every((translation) => translation.locale) ||
        !inputVariables.input.descriptionTranslations?.every((translation) => translation.locale)
    ) {
        // making sure that empty locale is not sent to the server
        const errorResponse = {
            status: HTTP.BAD_REQUEST,
        };
        return Observable.from(Promise.reject(errorResponse)).catch((error: AjaxError) => {
            return concat(
                Observable.of(updateLoginAnnouncementsFailure(errorResponse)),
                Observable.of(handleAjaxError(error))
            );
        });
    }

    return Observable.from(
        updateLoginAnnouncementsData(inputVariables).then((response) => {
            if (response.helpCenter?.updateLoginAnnouncement?.success) {
                // incase of mutation success, we get success as true and no errors
                return true;
            }
            // we get success as false and errors in case of mutation failure
            const errorResponse: ErrorResponse = {
                errorMessages:
                    response.helpCenter?.updateLoginAnnouncement?.errors?.map((error) => error.message ?? '') ?? [],
                status:
                    response.helpCenter?.updateLoginAnnouncement?.errors?.[0]?.extensions?.statusCode ?? HTTP.SERVER,
            };
            return Promise.reject(errorResponse);
        })
    )
        .flatMap(() => {
            if (meta?.analyticsSuccessEvent) {
                sendEvent(meta.analyticsSuccessEvent);
            }
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            updateLoginAnnouncementSideBarExperience.success();
            // unlink model mutation call, we do not get login announcements in response, hence using payload to call updateLoginAnnouncementsSuccess
            return concat(
                Observable.of(updateLoginAnnouncementsSuccess(payload)),
                Observable.of(resetLoginAnnouncements())
            );
        })
        .catch((error: AjaxError) => {
            if (meta?.analyticsFailureEvent && !isNetworkOrClientErrorCode(error.status)) {
                sendEvent(meta.analyticsFailureEvent);
            }
            if (!isNetworkOrClientErrorCode(error.status)) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                updateLoginAnnouncementSideBarExperience.failure({
                    metadata: {
                        error: error.message,
                    },
                });
            } else {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                updateLoginAnnouncementSideBarExperience.success();
            }
            // we are mapping the error, because mutation error in not always in form of AjaxError
            const errorResponse = {
                ...error,
                errorMessages: [error.message],
                status: error.status ?? HTTP.SERVER,
            };
            return concat(
                Observable.of(updateLoginAnnouncementsFailure(errorResponse)),
                Observable.of(handleAjaxError(errorResponse))
            );
        });
};

export const updateLoginAnnouncementsEpic: Epic<
    | UpdateLoginAnnouncementsModel
    | UpdateLoginAnnouncementsSuccess
    | UpdateLoginAnnouncementsFailure
    | HandleAjaxError
    | ResetLoginAnnouncementsSettings
> = (action$: ActionsObservable<UpdateLoginAnnouncementsModel>) => {
    return action$.ofType(UPDATE_LOGIN_ANNOUNCEMENTS_MODEL).mergeMap((action: UpdateLoginAnnouncementsModel) => {
        return gqlMutationLoginAnnouncements(action);
    });
};
