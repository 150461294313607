import React, { Suspense } from 'react';
import { noop } from 'lodash';
import { di } from 'react-magnetic-di';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { AnalyticsContext, withAnalyticsContext } from '@atlaskit/analytics-next';
import { Box, Grid, Stack } from '@atlaskit/primitives';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/analytics-bridge';
import { FireScreenEventOnMount } from '@atlassian/help-center-common-util/analytics/fire-screen-event';
import { getWorkspaceAri, getCloudId } from '@atlassian/help-center-common-util/meta';
import HelpCenterForm from '@atlassian/help-centers-management/src/ui/help-center-form/help-center-form';
import { useHelpCenterAri } from '../../hooks/use-help-center-ari';
import { AdminManagementLayout } from '../admin-management-layout';
import { SettingsBreadcrumbs } from '../settings-breadcrumbs';
import type { editHelpCenter_getHelpcenterById_Query } from './__generated__/editHelpCenter_getHelpcenterById_Query.graphql';
import { EditHelpCenterLayoutSkeleton } from './edit-helpcenter-layout-skeleton';

const HelpCenterEditDetailsComponent = () => {
    const cloudId = getCloudId();
    const helpCenterAri = useHelpCenterAri();
    const data = useLazyLoadQuery<editHelpCenter_getHelpcenterById_Query>(
        graphql`
            query editHelpCenter_getHelpcenterById_Query($helpCenterAri: ID!, $cloudId: ID!) {
                helpCenter(cloudId: $cloudId) @optIn(to: "HelpCenterAggBeta") {
                    helpCenterById(helpCenterAri: $helpCenterAri) {
                        ... on HelpCenter {
                            id
                            name {
                                default
                                translations {
                                    value
                                    locale
                                }
                            }
                            slug @optIn(to: "HelpCenterSlugTest")
                        }
                    }
                }
            }
        `,
        { cloudId, helpCenterAri }
    );

    const helpCenter = data.helpCenter?.helpCenterById;
    const { name, slug, id } = helpCenter ?? {};

    return (
        <ContextualAnalyticsData sourceName="helpCenterEditDetails" sourceType={SCREEN}>
            <Stack>
                {name?.default && <SettingsBreadcrumbs helpCenterName={name.default} slug={slug} />}
                <HelpCenterForm
                    helpCenterToEdit={{
                        translations:
                            name && name.translations
                                ? name.translations.map((translation) => ({
                                      value: translation?.value ?? '',
                                      locale: translation?.locale ?? '',
                                  }))
                                : [],
                        slug: slug ?? '',
                        name: name?.default ?? '',
                        ari: id ?? '',
                    }}
                    isStandaloneForm
                    isOpen
                    onClose={noop}
                />
            </Stack>
            <FireScreenEventOnMount />
        </ContextualAnalyticsData>
    );
};
export const HelpCenterEditDetails = () => {
    di(AdminManagementLayout);
    return (
        <AdminManagementLayout featureName="Helpcenter settings">
            <AnalyticsContext data={{ attributes: { workspaceAri: getWorkspaceAri() } }}>
                <Grid templateColumns="1fr 2fr 1fr" rowGap="space.400">
                    <Box />
                    <Suspense fallback={<EditHelpCenterLayoutSkeleton />}>
                        <HelpCenterEditDetailsComponent />
                    </Suspense>
                    <Box />
                </Grid>
            </AnalyticsContext>
        </AdminManagementLayout>
    );
};
export default withAnalyticsContext({ component: 'helpCenterEditDetails' })(HelpCenterEditDetails);
