import { get } from 'epics/ajax';
import type { Observable } from 'epics/rxjs';
import { isApolloStargatePathEnabled } from 'feature-flags';
import * as qs from 'query-string';
import { APOLLO_CONTEXT_ROOT, APOLLO_STARGATE_CONTEXT_ROOT } from 'rest/constants';
import type { KnowledgeBaseSearchResult as FormattedKnowledgeBaseSearchResult } from 'state/ui/search';
import { getMeta } from '@atlassian/help-center-common-util/meta';
import type { KnowledgeBaseSearchResponse, KnowledgeArticleResponse, KbsQuery } from './types';

export const searchKbs = (kbsQuery: KbsQuery): Observable<FormattedKnowledgeBaseSearchResult> => {
    const { projectId, query, resultLimit, expandPortalName, categoryIds, portalIds, labels } = kbsQuery;
    const queryParams = {
        labels,
        portalIds,
        categoryIds,
        searchQuery: query,
        limit: resultLimit,
        expand: expandPortalName ? 'portal' : undefined,
    };

    const queryString = qs.stringify(queryParams);
    let uri;

    if (projectId) {
        uri = isApolloStargatePathEnabled()
            ? // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `${APOLLO_STARGATE_CONTEXT_ROOT}/${getMeta(
                  'activation-id'
              )}/api/jsm-portal/project/${projectId}/articles?${queryString}`
            : `${APOLLO_CONTEXT_ROOT}/api/jsm-portal/project/${projectId}/articles?${queryString}`;
    } else {
        uri = isApolloStargatePathEnabled()
            ? // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `${APOLLO_STARGATE_CONTEXT_ROOT}/${getMeta('activation-id')}/api/jsm-portal/articles?${queryString}`
            : `${APOLLO_CONTEXT_ROOT}/api/jsm-portal/articles?${queryString}`;
    }

    return get(uri, { 'Content-Type': 'application/json' }).map((ajaxResponse) => {
        const result = ajaxResponse.response as KnowledgeBaseSearchResponse;
        return {
            ...result,
            articles: result.articles.map((article: KnowledgeArticleResponse) => ({
                id: article.articleId,
                name: article.articleTitle,
                articleSnippet: article.articleSnippet,
                pageVersion: article.articleVersion,
                score: article.snippetScore,
                isSmartSnippet: article.isSmartSnippet,
                linkedPortals: article.linkedPortals,
            })),
        };
    });
};
