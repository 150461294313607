import React from 'react';
import { Button } from '@helpCenter/view/common/analytics';
import { useIntl } from 'react-intl-next';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import messages from './messages';

export interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export const UnlinkAllProjectsWarningModal = ({ isOpen, onClose }: Props) => {
    const { formatMessage } = useIntl();
    return (
        <ModalTransition>
            {isOpen && (
                <Modal width="small" onClose={onClose}>
                    <ModalHeader>
                        <ModalTitle testId="help-center-unlink-all-projects-warning-modal-title">
                            {formatMessage(messages.unlinkAllWarningModalTitle)}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>{formatMessage(messages.unlinkAllWarningModalDescription)}</ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose} appearance="primary" actionSubjectId="unlinkAllWarningModalOkButton">
                            {formatMessage(messages.unlinkAllWarningModalOkButton)}
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </ModalTransition>
    );
};
