/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<8e6e49299578f12fddd7b32aab71a9de>>
 * @relayHash 2a875c8a250c37499587e860504dd6e2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 27ed9fd1a0cf4a907f7487a1e4ebede8171a42a1a45d2908056a3ee3862b4abf

import type { ConcreteRequest, Query } from 'relay-runtime';
export type loginAnnouncementsQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
};
export type loginAnnouncementsQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "HelpCenter";
      readonly announcements: {
        readonly loginAnnouncements: ReadonlyArray<{
          readonly descriptionTranslationsRaw: ReadonlyArray<{
            readonly locale: string;
            readonly localeDisplayName: string;
            readonly value: string;
          }> | null | undefined;
          readonly nameTranslationsRaw: ReadonlyArray<{
            readonly locale: string;
            readonly localeDisplayName: string;
            readonly value: string;
          }> | null | undefined;
        }> | null | undefined;
      } | null | undefined;
      readonly siteDefaultLanguageTag: string | null | undefined;
    } | {
      readonly __typename: "QueryError";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
};
export type loginAnnouncementsQuery = {
  response: loginAnnouncementsQuery$data;
  variables: loginAnnouncementsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "siteDefaultLanguageTag",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locale",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "localeDisplayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpCenterAnnouncements",
  "kind": "LinkedField",
  "name": "announcements",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HelpCenterAnnouncement",
      "kind": "LinkedField",
      "name": "loginAnnouncements",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpCenterTranslation",
          "kind": "LinkedField",
          "name": "nameTranslationsRaw",
          "plural": true,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpCenterTranslation",
          "kind": "LinkedField",
          "name": "descriptionTranslationsRaw",
          "plural": true,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "loginAnnouncementsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/)
                ],
                "type": "HelpCenter",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "QueryError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "loginAnnouncementsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/)
                ],
                "type": "HelpCenter",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "27ed9fd1a0cf4a907f7487a1e4ebede8171a42a1a45d2908056a3ee3862b4abf",
    "metadata": {},
    "name": "loginAnnouncementsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "76319886fc90231eee48f51d80798d47";

export default node;
