import * as React from 'react';
import type { InjectedIntlProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import { styled } from '@compiled/react';
import { isAdvancedCustomizationEnabled } from 'feature-flags';
import { bp } from 'view/styles/compiled/breakpoints';
import * as grid from 'view/styles/grid';
import { ButtonGroup } from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { colors, layers } from '@atlaskit/theme';
import { N50A, N60A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { Button } from '@atlassian/help-center-common-component/analytics';
import messages from './messages';
import type { ActionBarWithButtonsStatelessProps } from './types';

export const ActionsContent: React.FC<ActionBarWithButtonsStatelessProps & InjectedIntlProps> = ({
    intl,
    isDirty,
    openCancelPrompt,
    openPublishPrompt,
}) => {
    return (
        <SpotlightTarget name="portal-reordering-action-buttons">
            <ButtonContainer>
                <ButtonGroup>
                    <Button
                        appearance="subtle"
                        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                        className="portal-reordering-cancel-button"
                        onClick={openCancelPrompt}
                        actionSubjectId="cancelPortalsReorder"
                        data-test-id="cancel-portal-reorder-experience-action-bar"
                    >
                        {intl.formatMessage(messages.cancelButtonLabel)}
                    </Button>
                    <Button
                        appearance="primary"
                        isDisabled={!isDirty}
                        onClick={openPublishPrompt}
                        actionSubjectId="publishPortalsReorder"
                        data-test-id="publish-portal-reorder-experience-action-bar"
                    >
                        {intl.formatMessage(messages.publishButtonLabel)}
                    </Button>
                </ButtonGroup>
            </ButtonContainer>
        </SpotlightTarget>
    );
};

export class ActionBarWithButtonsStateless extends React.Component<
    React.PropsWithChildren<ActionBarWithButtonsStatelessProps & InjectedIntlProps>
> {
    render() {
        const { intl, children } = this.props;

        return (
            <Wrapper>
                <PageHeaderWrapper>
                    <PageHeader>
                        <Title>
                            <Heading level="h600" as="div">
                                {isAdvancedCustomizationEnabled()
                                    ? intl.formatMessage(messages.actionBarTitleNew)
                                    : intl.formatMessage(messages.actionBarTitle)}
                            </Heading>
                        </Title>
                        <ActionsContent {...this.props} />
                    </PageHeader>
                    {children}
                </PageHeaderWrapper>
            </Wrapper>
        );
    }
}
export default injectIntl(ActionBarWithButtonsStateless);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Wrapper = styled.header({
    boxShadow: token('elevation.shadow.overlay', `0 8px 16px -4px ${N50A}, 0 0 1px ${N60A}`),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    zIndex: layers.modal(),
    top: 0,
    left: 0,
    width: '100%',
    position: 'fixed',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    backgroundColor: token('elevation.surface.overlay', colors.N0),
    display: 'flex',
    justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const PageHeaderWrapper = styled.div({
    width: '100%',
    justifyContent: 'center',
    padding: `0 ${token('space.500', '40px')}`,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    [bp.from.fromLarge]: {
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        maxWidth: grid.multiple(145).px,
    },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ButtonContainer = styled.div({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    maxWidth: grid.multiple(96).px,
    padding: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Title = styled.div({
    marginTop: 0,
    marginBottom: token('space.200', '16px'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    [bp.from.fromMobile]: {
        marginBottom: 0,
    },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const PageHeader = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: `${token('space.250', '20px')} 0`,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    [bp.from.fromMobile]: {
        flexDirection: 'row',
    },
});
