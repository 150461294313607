import type { ForgePageName } from '@atlassian/help-center-common-component/forge-ui/types';

export const FORGE_HELP_CENTER: ForgePageName = 'help_center';
export const FORGE_PORTAL_HOME: ForgePageName = 'portal';
export const FORGE_REQUEST_CREATE: ForgePageName = 'create_request';
export const FORGE_REQUEST_DETAILS: ForgePageName = 'view_request';
export const FORGE_REQUEST_LIST: ForgePageName = 'my_requests';
export const FORGE_PROFILE: ForgePageName = 'profile';

export const REQUEST_DETAIL_MAIN_SECTION_MODULE = 'jiraServiceManagement:portalRequestDetail';
export const REQUEST_DETAIL_SIDE_SECTION_MODULE = 'jiraServiceManagement:portalRequestDetailPanel';
export const PORTAL_HEADER_MODULE = 'jiraServiceManagement:portalHeader';
export const PORTAL_FOOTER_MODULE = 'jiraServiceManagement:portalFooter';
export const PORTAL_SUBHEADER_MODULE = 'jiraServiceManagement:portalSubheader';
export const PROFILE_PANEL_MODULE = 'jiraServiceManagement:portalProfilePanel';
export const USER_MENU_ACTION_SECTION_MODULE = 'jiraServiceManagement:portalUserMenuAction';
export const REQUEST_VIEW_ACTION_MODULE = 'jiraServiceManagement:portalRequestViewAction';
export const PORTAL_REQUEST_CREATE_PROPERTY_PANEL_MODULE = 'jiraServiceManagement:portalRequestCreatePropertyPanel';

export const USER_MENU_ACTION_COMPONENT = 'PortalUserMenuAction';
export const REQUEST_VIEW_ACTION_COMPONENT = 'PortalRequestViewAction';

export const RESOURCE_TYPE_ISSUE = 'ISSUE';
export const RESOURCE_TYPE_PROJECT = 'PROJECT';

export const RESOURCE_TYPE_UNKNOWN = 'UNKNOWN';

export const TOP_LEVEL_COMPONENTS = {
    [REQUEST_DETAIL_MAIN_SECTION_MODULE]: 'PortalRequestDetail',
    [REQUEST_DETAIL_SIDE_SECTION_MODULE]: 'PortalRequestDetailPanel',
    [PORTAL_HEADER_MODULE]: 'PortalHeader',
    [PORTAL_FOOTER_MODULE]: 'PortalFooter',
    [PORTAL_SUBHEADER_MODULE]: 'PortalSubheader',
    [PROFILE_PANEL_MODULE]: 'PortalProfilePanel',
    [PORTAL_REQUEST_CREATE_PROPERTY_PANEL_MODULE]: 'PortalRequestCreatePropertyPanel',
};
