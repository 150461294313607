import type { ClientOptions, FeatureFlagUser } from '@atlassiansox/feature-flag-web-client';
import FeatureFlagWebClient, { Identifiers, EnvironmentType } from '@atlassiansox/feature-flag-web-client';
import { EnvType } from '@atlassian/help-center-common-util/env';
import type { FeatureFlagClientInterface } from './types';

const AH_FX3_API_KEY = {
    DEV: '484aac67-cdc1-4ace-8fc9-4f07e8a44830',
    STAGING: '6a633edd-e9d3-462e-aa20-0fff357bfe33',
    PROD: '57c8eb4f-aacb-46e7-b279-cdbdc600dc32',
};

export const mapConfigEnvToClientEnv = (environment: EnvType) => {
    switch (environment) {
        case EnvType.PROD:
            return EnvironmentType.PROD;
        case EnvType.STAGING:
            return EnvironmentType.STAGING;
        default:
            return EnvironmentType.DEV;
    }
};

const getClientKey = (environment: EnvironmentType) => {
    switch (environment) {
        case EnvironmentType.PROD:
            return AH_FX3_API_KEY.PROD;
        case EnvironmentType.STAGING:
            return AH_FX3_API_KEY.STAGING;
        default:
            return AH_FX3_API_KEY.DEV;
    }
};

let featureFlagClient: FeatureFlagWebClient;

export const initialiseFeatureFlagClient = ({
    analyticsClient,
    atlassianAccountId,
    tenantId,
    environment,
}: FeatureFlagClientInterface) => {
    const clientEnv = mapConfigEnvToClientEnv(environment);
    const featureFlagAPIKey = getClientKey(clientEnv);

    const featureFlagUser: FeatureFlagUser = {
        identifier: {
            type: Identifiers.TENANT_ID,
            value: tenantId,
        },
        additionalIdentifiers: {
            /**
             * atlassianAccountId can be null if not present in meta tags,
             * we shouldn't pass this value at all to the client instead of null
             */
            atlassianAccountId: atlassianAccountId ?? undefined,
        },
    };

    const featureFlagOptions: ClientOptions = {
        environment: clientEnv,
        productKey: 'atlassian-help',
        pollingInterval: clientEnv === EnvironmentType.PROD ? 1000 * 60 * 5 : 1000 * 30,
    };

    featureFlagClient = new FeatureFlagWebClient(
        featureFlagAPIKey,
        analyticsClient,
        featureFlagUser,
        featureFlagOptions
    );

    return featureFlagClient;
};

export const getFeatureFlagClient = () => featureFlagClient;
