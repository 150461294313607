import React from 'react';
import { compose } from 'redux';
import { isUnifiedHelpAddFilterForCustomerAccounts } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import type { RouteContext } from 'react-resource-router';
import { useQueryParam, usePathParam } from 'react-resource-router';
import { HelpCenterVirtualAgentResource } from 'unified-help-center-alpha/src/pages/conversation-page/resource';
import {
    HelpCenterConversationPage,
    PortalConversationPage,
    authPageMessage,
    callToActionUrlText,
    ErrorPage,
    Layout,
} from 'view/unified-help-center';
import {
    VA_ON_HC_CONVERSATION_PATH,
    ScreenName,
    VA_ON_PORTAL_CONVERSATION_PATH,
} from '@atlassian/help-center-common-component/constants';
import { withJsmAiConfigEnabled } from '@atlassian/help-center-common-component/with-jsm-ai-config';
import withOnlyAtlassianAccount from '@atlassian/help-center-common-component/with-only-atlassian-account';
import { withOnlyJiraAdmin } from '@atlassian/help-center-common-component/with-only-jira-admin';
import { withOnlyLoggedIn } from '@atlassian/help-center-common-component/with-only-logged-in';
import { withOnlyProjectAdmin } from '@atlassian/help-center-common-component/with-only-project-admin';
import { getHelpCenterUrl } from '@atlassian/help-center-common-util/url';

const Error = () => <ErrorPage />;

const WrappedConversationPage = () => (
    <Layout
        renderError={Error}
        component={HelpCenterConversationPage}
        screenName={ScreenName.CONVERSATION_PAGE}
        headerless
        footerless
    />
);

export const ConversationHomePage = () => {
    const WrappedComponent = isUnifiedHelpAddFilterForCustomerAccounts()
        ? compose(withOnlyLoggedIn({ redirectUrl: '/' }))(() => <WrappedConversationPage />)
        : compose(
              withOnlyLoggedIn({ redirectUrl: '/' }),
              withOnlyAtlassianAccount()
          )(() => <WrappedConversationPage />);
    return <WrappedComponent />;
};

export const HelpCenterConversationPageRoute = () => {
    di(withJsmAiConfigEnabled, withOnlyJiraAdmin);
    const { formatMessage } = useIntl();

    const forbiddenPageOptions = {
        message: formatMessage(authPageMessage),
        callToActionText: formatMessage(callToActionUrlText),
        callToActionUrl: getHelpCenterUrl(),
    };

    const enhancer = compose(withOnlyLoggedIn({ redirectUrl: '/' }), withJsmAiConfigEnabled(forbiddenPageOptions));

    const WrappedComponent = enhancer(() => <WrappedConversationPage />);
    return <WrappedComponent />;
};

export const getHelpCenterConversationPageRoute = () => {
    const viewConversationPageRoute = {
        name: ScreenName.CONVERSATION_PAGE,
        path: VA_ON_HC_CONVERSATION_PATH,
        exact: true,
        resources: [HelpCenterVirtualAgentResource],
        component: ConversationHomePage,
    };

    return [
        {
            ...viewConversationPageRoute,
            component: HelpCenterConversationPageRoute,
        },
    ];
};

export const PortalConversationPageRoute = (props: RouteContext) => {
    di(withJsmAiConfigEnabled, withOnlyProjectAdmin);
    const { formatMessage } = useIntl();
    const [isTestConversationParam] = useQueryParam('isTestConversation');
    const [portalIdAsString] = usePathParam('portalId');

    const forbiddenPageOptions = {
        message: formatMessage(authPageMessage),
        callToActionText: formatMessage(callToActionUrlText),
        callToActionUrl: portalIdAsString ? `/portal/${portalIdAsString}` : getHelpCenterUrl(),
    };

    const enhancer = compose(
        withOnlyLoggedIn({ redirectUrl: '/' }),
        ...(isTestConversationParam === 'true' ? [withOnlyProjectAdmin(forbiddenPageOptions)] : [])
    );

    const WrappedComponent = enhancer(() => (
        <Layout
            renderError={Error}
            component={PortalConversationPage}
            screenName={ScreenName.CONVERSATION_PAGE}
            headerless
            footerless
        />
    ));
    return <WrappedComponent {...props} />;
};

export const viewPortalConversationPageRoute = {
    name: ScreenName.CONVERSATION_PAGE,
    path: VA_ON_PORTAL_CONVERSATION_PATH,
    exact: true,
    component: PortalConversationPageRoute,
};
