import type { State } from 'state';

export const getServiceDeskVersion = (state: State) => state.persisted.env.serviceDeskVersion;
export const getWacLink = (state: State) => state.persisted.env.wacLink;
export const getHeadless = (state: State) => state.persisted.env.headless;
export const getXsrfToken = (state: State) => state.persisted.env.xsrfToken;
export const getContactLink = (state: State) => state.persisted.env.contactLink;
export const getIsAnonymousEnabled = (state: State) => state.persisted.env.anonymousEnabled;
export const getFabricEmojiUrl = (state: State) => state.persisted.env.fabricEmojiUrl;
export const getBaseUrl = (state: State) => state.persisted.env.baseUrl;
export const getIsAIAnswersEnabled = (state: State) => !!state.persisted.env.featuredValues?.hcAIAnswersEnabled;
export const getJsmAIConfigEnabled = (state: State) => !!state.persisted.env.featuredValues?.jsmAiConfigEnabled;
export const getHelpCenterType = (state: State) => state.persisted.env.helpCenterType;
export const getHoistedProjectKey = (state: State) => state.persisted.env.hoistedProjectKey;
export const getJiraDisplayUrl = (state: State) => state.persisted.env.jiraDisplayUrl;
