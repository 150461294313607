/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<739bdd7e22b697877f10d0844c69e23c>>
 * @relayHash a3c94631477041eb05ce53a47942ce84
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8f91921ccf1741b3d27b8f1529add35763c1f7f9526b60dca2ffcb4e762b9352

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type srcHelpCenterVirtualAgentSettingsQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
  isJsmAIConfigEnabled: boolean;
  isVAOnHelpCenterGAEnabled: boolean;
};
export type srcHelpCenterVirtualAgentSettingsQuery$data = {
  readonly helpCenter?: {
    readonly helpCenterById: {
      readonly __typename: "HelpCenter";
      readonly virtualAgentEnabled: boolean | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"settingsVirtualAgentSettingsTabFragment">;
    } | {
      readonly __typename: "QueryError";
      readonly message: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"settingsVirtualAgentSettingsTabMappedProjectsFragment">;
};
export type srcHelpCenterVirtualAgentSettingsQuery = {
  response: srcHelpCenterVirtualAgentSettingsQuery$data;
  variables: srcHelpCenterVirtualAgentSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isJsmAIConfigEnabled"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isVAOnHelpCenterGAEnabled"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "kind": "Variable",
  "name": "helpCenterAri",
  "variableName": "helpCenterAri"
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "virtualAgentEnabled",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "QueryError",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "kind": "InlineFragment",
  "selections": (v9/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v11 = [
  (v1/*: any*/),
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "helpCenterARI",
        "variableName": "helpCenterAri"
      },
      {
        "kind": "Literal",
        "name": "helpCenterId",
        "value": ""
      },
      {
        "kind": "Literal",
        "name": "helpCenterMappingStatus",
        "value": "LINKED"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "srcHelpCenterVirtualAgentSettingsQuery",
    "selections": [
      {
        "condition": "isJsmAIConfigEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "HelpCenterQueryApi",
            "kind": "LinkedField",
            "name": "helpCenter",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "helpCenterById",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "settingsVirtualAgentSettingsTabFragment"
                      },
                      (v6/*: any*/)
                    ],
                    "type": "HelpCenter",
                    "abstractKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isVAOnHelpCenterGAEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Variable",
                "name": "isVAOnHelpCenterGAEnabled",
                "variableName": "isVAOnHelpCenterGAEnabled"
              }
            ],
            "kind": "FragmentSpread",
            "name": "settingsVirtualAgentSettingsTabMappedProjectsFragment"
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "srcHelpCenterVirtualAgentSettingsQuery",
    "selections": [
      {
        "condition": "isJsmAIConfigEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "HelpCenterQueryApi",
            "kind": "LinkedField",
            "name": "helpCenter",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "helpCenterById",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "type": "HelpCenter",
                    "abstractKey": null
                  },
                  (v7/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isVAOnHelpCenterGAEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "condition": "isVAOnHelpCenterGAEnabled",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JiraQuery",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "JiraProjectConnection",
                    "kind": "LinkedField",
                    "name": "jiraProjectsMappedToHelpCenter",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JiraProjectEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JiraProject",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "JiraAvatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "small",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": (v2/*: any*/),
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "knowledgeBaseArticlesCount",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "count",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "KnowledgeBaseArticleCountSource",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "virtualAgentConfiguration",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "isAiResponsesEnabled",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "liveIntentsCount",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "VirtualAgentConfiguration",
                                    "abstractKey": null
                                  },
                                  (v10/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v9/*: any*/),
                                    "type": "VirtualAgentQueryError",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "filters": [
                      "cloudId",
                      "filter"
                    ],
                    "handle": "connection",
                    "key": "projectBreakdownTableMappedProjectsFragment_jiraProjectsMappedToHelpCenter",
                    "kind": "LinkedHandle",
                    "name": "jiraProjectsMappedToHelpCenter"
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "8f91921ccf1741b3d27b8f1529add35763c1f7f9526b60dca2ffcb4e762b9352",
    "metadata": {},
    "name": "srcHelpCenterVirtualAgentSettingsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "df6394c5663f11733bbf12de2a83251a";

export default node;
