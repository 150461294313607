import { isWrmOptimizationsEnabled } from 'feature-flags';
import type { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ANALYTICS_BRIDGE_CHANNEL } from '@atlassian/analytics-bridge';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';

import { operationalEvent } from '@atlassian/help-center-common-util/analytics/events';
import { trackLegacyEvent } from '@atlassian/help-center-common-util/legacy-tracking';
import { reportError } from '@atlassian/help-center-common-util/sentry';
import type { AdditionalErrorData } from '@atlassian/help-center-common-util/sentry/sentry';
import { reportAnalyticsEvent as reportAnalyticsEventToSentryBreadcrumbs } from '@atlassian/help-center-common-util/sentry-breadcrumbs';

import { getAnalyticsWebClient } from '../client';
import { sessionId } from '../session-id';

interface LegacyEventData {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

const getMeta = () => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-explicit-any
    const buildVersion = (window as any).JSD_CUSTOMER_PORTAL_BUILD_VERSION;
    return {
        sessionId,
        context: window.location.pathname,
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        version: buildVersion ? buildVersion.buildKey : 'JPF-STORYBOOK-000',
    };
};

/**
 * Used to track events that are tracked by createAnalyticsEvent({}).fire
 * For new events it should use sendEventFromAnalyticsBridge instead.
 * @deprecated
 */
export function legacySendEvent(event: UIAnalyticsEvent) {
    sendEvent(event);
}

export function sendEventFromAnalyticsBridge(event: UIAnalyticsEvent) {
    sendEvent(event, ANALYTICS_BRIDGE_CHANNEL);
}

function sendEvent(event: UIAnalyticsEvent, channel?: string) {
    const analyticsWebClient = getAnalyticsWebClient();
    if (analyticsWebClient) {
        analyticsWebClient.sendEvent(event, channel);
    }

    reportAnalyticsEventToSentryBreadcrumbs(event);
}

const extractFromEventContext = (propertyNames: string[], event: UIAnalyticsEvent) =>
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    event.context
        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
        // eslint-disable-next-line @typescript-eslint/ban-types
        .reduceRight((acc: {}[], contextItem: {}) => {
            propertyNames.forEach((propertyName) => {
                // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                acc.push(contextItem[propertyName]);
            });
            return acc;
        }, [])
        .filter(Boolean);

export function sendAtlaskitUIEvent(event: UIAnalyticsEvent) {
    const analyticsWebClient = getAnalyticsWebClient();
    if (analyticsWebClient) {
        event.update({
            // Don't override analyticsType if it already exists.
            // Else set it to UI_EVENT_TYPE.
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            analyticsType: event.payload.analyticsType || UI_EVENT_TYPE,
            // Extract actionSubjectId from context.
            // Analytics web react enforces it must be defined here - else it won't pick it up.
            // See: https://bitbucket.org/atlassian/analytics-web-react/src/master/src/utils/extract-data-from-event.js?at=master#lines-89

            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            actionSubjectId: extractFromEventContext(['actionSubjectId'], event)[0],
        });
        analyticsWebClient.sendEvent(event);
    }
}

/**
 * Used to track a non-error analytic event.
 * For errors use {#trackError} instead.
 * @deprecated
 */
export function legacyTrackEvent(eventName: string, data: LegacyEventData) {
    const mergedData = {
        ...data,
        ...getMeta(),
    };

    trackLegacyEvent(eventName, mergedData);
}

function truncateMessage(message: string | undefined, length: number = 25) {
    if (message === null || message === undefined) {
        return 'null';
    }

    return message.slice(0, length);
}

function sendErrorToSentry(error: Error, additionalErrorData?: AdditionalErrorData) {
    try {
        // log to sentry
        reportError(error, additionalErrorData);
    } catch (ex) {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access ,@typescript-eslint/no-unsafe-assignment
        const errorMessage = JSON.stringify({ name: ex.name, msg: ex.message });
        legacyTrackEvent('sentry.error.tracking.failed', {
            error: errorMessage,
        });

        operationalEvent({
            action: 'failed',
            actionSubjectId: 'SentryErrorTracking',
            attributes: {
                error: errorMessage,
                ...additionalErrorData,
            },
            source: 'unknownSource',
        });
    }
}

/**
 * this error tracking event is to send the caught sentry error to the newer GASv3 analytics as an
 * operational event
 */
function sendOperationalErrorEvent(eventName: string, error: string) {
    operationalEvent({
        action: 'failed',
        actionSubjectId: 'SentryErrorTracking',
        actionSubject: 'sentryError',
        source: 'unknownSource',
        attributes: {
            error,
            eventName,
        },
    });
}

export function getErrorData(error: Error | AjaxError | string | undefined) {
    let errorData = '';

    if (!error) {
        // undefined error
        errorData = JSON.stringify({
            msg: 'null',
        });
    } else if (!!(error as AjaxError).xhr) {
        // ajax error
        const ajaxError = error as AjaxError;
        const xhr = ajaxError.xhr as XMLHttpRequest;
        errorData = JSON.stringify({
            name: 'AjaxError', // we can hard code this here because we know it's an ajax error
            stat: xhr.status,
            msg: truncateMessage(ajaxError.message),
        });
    } else if (typeof error === 'string') {
        // error as string
        errorData = JSON.stringify({
            msg: error as string,
        });
    } else {
        // generic js error
        const jsError = error as Error;
        errorData = JSON.stringify({
            name: truncateMessage(jsError.name),
            msg: truncateMessage(jsError.message, 100),
        });
    }

    return errorData;
}

/**
 * Used to track an analytic event that is fired when we experience a JS or Http error.
 *
 * This will try to pull out the relevant information from the error and add them to the analytic event as additional metadata.
 * Additional metadata that will be added to the event include:
 *  - error
 * These need to be added to the whitelist.json
 */
export function trackError(eventName: string, data: LegacyEventData, error: Error | AjaxError | string | undefined) {
    const errorData = getErrorData(error);
    if (error && !(error as AjaxError).xhr) {
        if (typeof error === 'string') {
            sendErrorToSentry(new Error(error), data);
        } else {
            // generic js error
            const jsError = error as Error;
            sendErrorToSentry(jsError, data);
        }
    }

    /**  behind the WRM optimisation feature flag and do not fire
     *  operational event for errors caught by error boundary
     */
    if (isWrmOptimizationsEnabled() && !(data && data.errorBoundaryId)) {
        sendOperationalErrorEvent(eventName, errorData);
    }

    const eventData = { ...data, error: errorData };
    return legacyTrackEvent(eventName, eventData);
}
