import { getIsPageServerSideRendered } from 'util/env';
import { enableRelaySubscriptions } from 'feature-flags';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import type { Variables, RequestParameters, CacheConfig, UploadableMap } from 'relay-runtime';
import fetchGraphQL from '../fetch';
import fetchGraphQLWithObservable from '../fetch-v2';
import { subscribe } from '../subscribe';
import { isSSR } from '../subscription-constants/subscription-constants';

const fetchRelay = (
    params: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    uploadables: UploadableMap
) => {
    const fetcher = __SERVER__ || getIsPageServerSideRendered() ? fetchGraphQLWithObservable : fetchGraphQL;

    return fetcher(params, variables, cacheConfig, uploadables);
};

const getNetWork = () => {
    if (!enableRelaySubscriptions() || isSSR()) return Network.create(fetchRelay);
    return Network.create(fetchRelay, subscribe);
};

// Export a singleton instance of Relay Environment configured with our network function:
export default new Environment({
    network: getNetWork(),
    store: new Store(new RecordSource()),
});
