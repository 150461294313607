// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import * as mixins from 'view/styles/mixins';

interface FakeButtonContainerProps {
    isCursorGrab?: boolean;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FakeButtonContainer = styled.div<FakeButtonContainerProps>`
    ${mixins.focusRing.default};
    display: inline-block;
    cursor: pointer;

    &[aria-disabled='true'] {
        ${mixins.focusRing.none};
        ${(props) => (props.isCursorGrab ? 'cursor: grab' : 'cursor: default')};
    }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export default FakeButtonContainer;
