import * as React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { Flex, Box, Stack, xcss } from '@atlaskit/primitives';
import { SkeletonItem } from '@atlassian/help-center-common-component/skeleton-item';
import { MANAGE_PROJECT_HEAD_WIDTH } from '../../constants';

interface ManageProjectsLayoutSkeletonProps {
    staticContent?: boolean;
}
const ManageProjectsLayoutSkeleton = ({ staticContent = true }: ManageProjectsLayoutSkeletonProps) => {
    const head = {
        cells: [
            {
                content: <SkeletonItem width="12px" height="12px" />,
                width: MANAGE_PROJECT_HEAD_WIDTH['select-all'],
            },
            {
                content: <SkeletonItem width="30%" height="16px" />,
                width: MANAGE_PROJECT_HEAD_WIDTH['project-name'],
            },
            {
                content: <SkeletonItem width="30%" height="16px" />,
                width: MANAGE_PROJECT_HEAD_WIDTH['lead-name'],
            },
            {
                content: <SkeletonItem width="30%" height="16px" />,
                width: MANAGE_PROJECT_HEAD_WIDTH.status,
            },
            {
                content: <SkeletonItem width="30%" height="16px" />,
                width: MANAGE_PROJECT_HEAD_WIDTH['action-button'],
            },
        ],
    };
    const rows = Array.from({ length: 10 }).map((_, index) => ({
        key: `${index}`,
        cells: [
            {
                content: <SkeletonItem width="12px" height="12px" />,
            },
            {
                content: (
                    <Box paddingBlock="space.050">
                        <Flex gap="space.100" alignItems="center">
                            <SkeletonItem width="24px" height="24px" />
                            <SkeletonItem width="60%" height="20px" />
                        </Flex>
                    </Box>
                ),
            },
            {
                content: (
                    <Box paddingBlock="space.050">
                        <Flex gap="space.100" alignItems="center">
                            <SkeletonItem width="24px" height="24px" isCircle />
                            <SkeletonItem width="60%" height="20px" />
                        </Flex>
                    </Box>
                ),
            },
            {
                key: 'select-all-skeleton',
                content: <SkeletonItem width="28%" height="16px" />,
            },
            {
                content: <SkeletonItem width="30%" height="20px" />,
            },
        ],
    }));
    return (
        <>
            <Stack space="space.300">
                {staticContent && (
                    <Box paddingBlockStart="space.400">
                        <Flex direction="row" gap="space.100">
                            <SkeletonItem width="84px" height="24px" />
                            <SkeletonItem width="84px" height="24px" />
                        </Flex>
                    </Box>
                )}
                {staticContent && (
                    <SkeletonItem width="92px" height="28px" data-testid="manage-project-title-skeleton" />
                )}
                {staticContent && (
                    <Stack space="space.050">
                        <SkeletonItem width="100%" height="20px" />
                        <SkeletonItem width="50%" height="20px" />
                    </Stack>
                )}
                <Flex direction="row" gap="space.075">
                    <SkeletonItem width="270px" height="16px" />
                    <SkeletonItem width="32px" height="16px" data-testid="toggle-new-projects-layout-skeleton" />
                </Flex>
            </Stack>
            <Box paddingBlock="space.200">
                <Flex direction="row" gap="space.075">
                    <SkeletonItem width="25%" height="32px" />
                    <SkeletonItem width="20%" height="32px" />
                </Flex>
            </Box>
            <Box xcss={tableSkeletonStyles}>
                <DynamicTable head={head} rows={rows} isFixedSize isRankingDisabled highlightedRowIndex={11} />
            </Box>
        </>
    );
};

const tableSkeletonStyles = xcss({
    pointerEvents: 'none',
});

export default ManageProjectsLayoutSkeleton;
