import { post } from 'epics/ajax';
import type { Observable } from 'epics/rxjs';
import { isFixSignUpRoutingForMultiHCEnabled } from 'feature-flags';
import { contextPath } from '@atlassian/help-center-common-util/history';
import { getHelpCenterAri } from '@atlassian/help-center-common-util/meta';

const baseSignupBody = (email: string, portalId: number | undefined, redirectUrl: string | undefined) => ({
    email,
    signUpContext: {
        portalId,
        redirectUrl,
        helpCenterAri: isFixSignUpRoutingForMultiHCEnabled() ? getHelpCenterAri() : undefined,
    },
});

export const signUp = async (
    email: string,
    secondaryEmail: string,
    portalId: number | undefined,
    redirectUrl: string | undefined,
    context: string = contextPath
): Promise<unknown> => {
    const uri = `${context}/rest/servicedesk/1/customer/pages/user/signup`;
    const body = {
        ...baseSignupBody(email, portalId, redirectUrl),
        secondaryEmail,
        usingNewUi: true,
    };
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    return (
        post(uri, body, headers)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map((ajaxResponse) => ajaxResponse.response)
            .toPromise()
            .catch((e) => Promise.reject(e))
    );
};

export const resendValidationEmail = (
    email: string,
    portalId: number | undefined,
    redirectUrl: string | undefined,
    context: string = contextPath
): Observable<unknown> => {
    const uri = `${context}/rest/servicedesk/1/customer/pages/user/send-validation-email`;
    const body = baseSignupBody(email, portalId, redirectUrl);
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return post(uri, body, headers).map((ajaxResponse) => ajaxResponse.response);
};
