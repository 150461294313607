import * as React from 'react';
import { isHelpCentersManagement, isKoshEnabledForDefaultHelpCenter } from 'util/advanced-help-center';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchHelpCenterBrandingAction } from 'epics/model/help-center';
import { fetchOrganisationsModelAction } from 'epics/model/organisations';
import { fetchUserModelAction } from 'epics/model/user';
import { fetchUserRequestsCountsAction } from 'epics/model/user-requests-counts';
import { isAdvancedCustomizationEnabled } from 'feature-flags';
import type { State } from 'state';
import { getPathname, getPortalId } from 'state/selectors/context';
import { getIsAnonymousEnabled } from 'state/selectors/env';
import { getHelpCenterBrandingLoader } from 'state/selectors/help-center';
import { isCustomizing } from 'state/selectors/help-center-preview';
import { getOrganisations } from 'state/selectors/organisations';
import { isLoggedIn, isUserLoaded } from 'state/selectors/user';
import { getUserRequestsCounts } from 'state/selectors/user-requests-counts';
import { LoadingSkeleton } from 'view/common/loading';
import { HEADER_HEIGHT } from 'view/common/styled';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { getLoginUrl } from '@atlassian/help-center-common-util/url';
import type { DumbProps } from '../header-dumb';
import { DumbHeader } from '../header-dumb';

interface Props extends DumbProps {
    isAnonymous?: boolean;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    fetchBranding: () => any;
    fetchUser: typeof fetchUserModelAction;
    fetchUserRequestsCounts: typeof fetchUserRequestsCountsAction;
    fetchOrganisations: () => any;
    isUserLoaded: boolean;
    userLoggedIn: boolean;
    customizing?: boolean;
    loginUrl: string;
    isAdvancedHelpCenter?: boolean;
    disableAllHeaderLinks?: boolean;
    isBrandingLoaded?: boolean;
    isModelCallPrefetched?: boolean;
}

const getHeaderLoginUrl = (state: State) => {
    const portalId = getPortalId(state);
    return getLoginUrl({
        portalId,
        isAbsolute: true,
        redirectUrl: getPathname(state),
    });
};

const userRequestsSelector = (state: State) => {
    return getUserRequestsCounts(state);
};

const selector = createSelector(
    isLoggedIn,
    getHeaderLoginUrl,
    isUserLoaded,
    isCustomizing,
    userRequestsSelector,
    getOrganisations,
    getIsAnonymousEnabled,
    getHelpCenterBrandingLoader,
    (
        userLoggedIn,
        loginUrl,
        userLoaded,
        customizing,
        userRequest,
        organisations,
        anonymousEnabled,
        isBrandingLoaded
    ) => ({
        userLoggedIn,
        loginUrl,
        userRequest,
        organisations,
        anonymousEnabled,
        isBrandingLoaded,
        isUserLoaded: userLoaded,
        isCustomizing: customizing,
    })
);

class Header extends React.Component<Props> {
    componentDidMount() {
        if (!this.props.isModelCallPrefetched) {
            if (!isHelpCentersManagement()) {
                this.props.fetchBranding();
            }
            if (!this.props.isAnonymous) {
                this.props.fetchUser();
                this.props.fetchOrganisations();
                this.props.fetchUserRequestsCounts();
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        // If we haven't loaded the user yet and we've moved from an
        // anonymous route to a normal route, go fetch the user.
        if (
            !this.props.isUserLoaded &&
            prevProps.isAnonymous &&
            !this.props.isAnonymous &&
            !this.props.isModelCallPrefetched
        ) {
            this.props.fetchUser();
            this.props.fetchUserRequestsCounts();
        }
    }

    render() {
        if (isAdvancedCustomizationEnabled() && isKoshEnabledForDefaultHelpCenter() && !isHelpCentersManagement()) {
            if (this.props.isBrandingLoaded) {
                return <DumbHeader {...this.props} />;
            }
            return (
                <UFOLoadHold name="header-loader">
                    <LoadingSkeleton
                        data-testid="header-skeleton"
                        height={HEADER_HEIGHT}
                        backgroundColor={token('color.skeleton.subtle', colors.N20)}
                    />
                </UFOLoadHold>
            );
        }
        return <DumbHeader {...this.props} />;
    }
}

export default connect(selector, {
    fetchBranding: fetchHelpCenterBrandingAction,
    fetchUser: fetchUserModelAction,
    fetchOrganisations: fetchOrganisationsModelAction,
    fetchUserRequestsCounts: fetchUserRequestsCountsAction,
})(Header);
