import { isNetworkOrClientErrorCode } from 'util/error-codes';
import type { AjaxError } from 'rxjs';
import type { InvalidateHelpCenterStoreAction } from 'epics/model/help-center';
import { invalidateHelpCenterStoreAction } from 'epics/model/help-center';
import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';
import type { UpdateBrandingBody, HCBrandingResponse } from 'rest/help-center-branding';
import { updateBranding, updateAnnouncement } from 'rest/help-center-branding';

import type { HandleAjaxError } from 'state/actions/flags';
import { handleAjaxError } from 'state/actions/flags';
import { updateHCBrandingModelAction } from 'state/actions/help-center';
import type { UpdateHCBrandingModelAction } from 'state/actions/help-center';
import {
    UPDATE_HELP_CENTER_BRANDING,
    UPDATE_HELP_CENTER_BRANDING_MUTATION_SUCCESS,
    UPDATE_HELP_CENTER_BRANDING_MUTATION_FAILURE,
    clearHCPreviewModelAction,
    updateBrandingFailure,
} from 'state/actions/help-center-preview';
import type {
    UpdateBranding,
    ClearHCPreviewModelAction,
    UpdateBrandingFailure,
    UpdateBrandingMutationSuccess,
    UpdateBrandingMutationFailure,
} from 'state/actions/help-center-preview';
import { updateThemeSideBarExperience } from 'view/help-center-customize-sidebar/bucket-routes/experiences';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';

const getAnnouncementBodyFromHCBrandingBody = (body: UpdateBrandingBody, siteDefaultLanguageTag: string) => {
    return {
        header: body.translations[siteDefaultLanguageTag].announcementHeader,
        message: body.translations[siteDefaultLanguageTag].announcementMessage,
    };
};

/*
    Different APIs for admins and agents. Admins have permissions to update help center branding along
    with announcements so help center branding api is used. For the agents on the other hand announcement API is used.
    TODO: Separate out announcements from help center branding.
*/
const getUpdateBrandingPromise = (
    body: UpdateBrandingBody,
    canAdministerJira: boolean,
    rawBrandingResponse?: HCBrandingResponse
) => {
    return !canAdministerJira && rawBrandingResponse
        ? updateAnnouncement(getAnnouncementBodyFromHCBrandingBody(body, rawBrandingResponse.siteDefaultLanguageTag))
        : updateBranding(body);
};

export const updateHelpCenterBrandingEpic: Epic<
    UpdateBranding,
    ClearHCPreviewModelAction | UpdateHCBrandingModelAction | HandleAjaxError | UpdateBrandingFailure
> = (action$, store) => {
    return action$.ofType(UPDATE_HELP_CENTER_BRANDING).mergeMap((updateBrandingAction) => {
        const { payload, meta } = updateBrandingAction;
        const { body } = payload;

        const state = store.getState();
        const canAdministerJira = state.persisted.user.canAdministerJIRA;
        const rawBrandingResponse = state.persisted.helpCenter.rawBrandingResponse;

        return getUpdateBrandingPromise(body, canAdministerJira, rawBrandingResponse)
            .flatMap((response) => {
                if (meta?.analyticsSuccessEvent) {
                    sendEvent(meta.analyticsSuccessEvent);
                }

                const modifiedResponse = rawBrandingResponse
                    ? { ...rawBrandingResponse, ...response }
                    : (response as HCBrandingResponse);

                return concat(
                    Observable.of(updateHCBrandingModelAction(modifiedResponse)),
                    Observable.of(clearHCPreviewModelAction())
                );
            })
            .catch((error: AjaxError) => {
                if (meta?.analyticsFailureEvent && error.status >= 500) {
                    sendEvent(meta.analyticsFailureEvent);
                }
                return concat(Observable.of(updateBrandingFailure()), Observable.of(handleAjaxError(error)));
            });
    });
};

export const updateHelpCenterBrandingEpicMutationSuccess: Epic<
    UpdateBrandingMutationSuccess,
    | UpdateBranding
    | ClearHCPreviewModelAction
    | UpdateHCBrandingModelAction
    | HandleAjaxError
    | UpdateBrandingFailure
    | InvalidateHelpCenterStoreAction
> = (action$, store) => {
    return action$
        .ofType(UPDATE_HELP_CENTER_BRANDING_MUTATION_SUCCESS)
        .mergeMap((updateBrandingMutationSuccessAction) => {
            const { response, meta } = updateBrandingMutationSuccessAction;

            const state = store.getState();
            // const canAdministerJira = state.persisted.user.canAdministerJIRA;
            const rawBrandingResponse = state.persisted.helpCenter.rawBrandingResponse;
            if (meta?.analyticsSuccessEvent) {
                sendEvent(meta.analyticsSuccessEvent);
            }
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            updateThemeSideBarExperience.success();
            const modifiedResponse = rawBrandingResponse
                ? { ...rawBrandingResponse, ...response }
                : (response as HCBrandingResponse);
            return concat(
                Observable.of(updateHCBrandingModelAction(modifiedResponse)),
                Observable.of(clearHCPreviewModelAction()),
                Observable.of(invalidateHelpCenterStoreAction())
            );
        });
};

export const updateHelpCenterBrandingEpicMutationFailure: Epic<
    UpdateBrandingMutationFailure,
    UpdateBranding | ClearHCPreviewModelAction | UpdateHCBrandingModelAction | HandleAjaxError | UpdateBrandingFailure
> = (action$) => {
    return action$
        .ofType(UPDATE_HELP_CENTER_BRANDING_MUTATION_FAILURE)
        .mergeMap((updateBrandingMutationFailureAction) => {
            const { error, meta } = updateBrandingMutationFailureAction;

            //const state = store.getState();
            // const canAdministerJira = state.persisted.user.canAdministerJIRA;
            // const rawBrandingResponse = state.persisted.helpCenter.rawBrandingResponse;

            return Observable.from(Promise.reject(error)).catch((err: AjaxError) => {
                if (meta?.analyticsFailureEvent && !isNetworkOrClientErrorCode(err.status)) {
                    sendEvent(meta.analyticsFailureEvent);
                }
                if (!isNetworkOrClientErrorCode(err.status)) {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    updateThemeSideBarExperience.failure({
                        metadata: {
                            error: err.message,
                        },
                    });
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    updateThemeSideBarExperience.success();
                }
                return concat(Observable.of(updateBrandingFailure()), Observable.of(handleAjaxError(err)));
            });
        });
};
