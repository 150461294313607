/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<e9be273b1b96066ff8e5996a5d4bcccb>>
 * @relayHash bc9208b8383b4379d6522b5fdb987c6f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 918ff53386398ce6c4aa6b0acf2bb52fe5105df3c15fb0c08eeebd8fc67713ff

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type HelpCenterSortOrder = "CREATED_DATE_ASCENDING" | "CREATED_DATE_DESCENDING" | "%future added value";
export type multiHcDropdownContentComponentQuery$variables = {
  NUMBER_OF_HELP_CENTERS_PER_FETCH: number;
  SORT_ORDER: HelpCenterSortOrder;
  cloudId: string;
  workspaceAri: string;
};
export type multiHcDropdownContentComponentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"multiHcDropdownPaginatedFragment">;
};
export type multiHcDropdownContentComponentQuery = {
  response: multiHcDropdownContentComponentQuery$data;
  variables: multiHcDropdownContentComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "NUMBER_OF_HELP_CENTERS_PER_FETCH"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SORT_ORDER"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceAri"
},
v4 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v5 = {
  "kind": "Variable",
  "name": "workspaceAri",
  "variableName": "workspaceAri"
},
v6 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "NUMBER_OF_HELP_CENTERS_PER_FETCH"
  },
  {
    "kind": "Variable",
    "name": "sortOrder",
    "variableName": "SORT_ORDER"
  },
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "multiHcDropdownContentComponentQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "NUMBER_OF_HELP_CENTERS_PER_FETCH",
            "variableName": "NUMBER_OF_HELP_CENTERS_PER_FETCH"
          },
          {
            "kind": "Variable",
            "name": "SORT_ORDER",
            "variableName": "SORT_ORDER"
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "multiHcDropdownPaginatedFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "multiHcDropdownContentComponentQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v4/*: any*/)
        ],
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "HelpCenterQueryResultConnection",
            "kind": "LinkedField",
            "name": "helpCenters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HelpCenterQueryResultEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HelpCenterName",
                            "kind": "LinkedField",
                            "name": "name",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "default",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "slug",
                            "storageKey": null
                          }
                        ],
                        "type": "HelpCenter",
                        "abstractKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": [
              "workspaceAri",
              "sortOrder"
            ],
            "handle": "connection",
            "key": "multiHcDropdownContentFragment_helpCenters",
            "kind": "LinkedHandle",
            "name": "helpCenters"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "918ff53386398ce6c4aa6b0acf2bb52fe5105df3c15fb0c08eeebd8fc67713ff",
    "metadata": {},
    "name": "multiHcDropdownContentComponentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "29fda4db4acf34a38aedc1fa8b96de91";

export default node;
